<template>
  <HeaderAdmin v-if="accountData.role===3"></HeaderAdmin>
  <AdminIndex v-if="accountData.role===3"></AdminIndex>
</template>

<script>
import HeaderAdmin from "@/components/HeaderAdmin";
import router from "@/router";
import AdminIndex from "@/components/AdminIndex";
export default {
  name: "routerAdmin",
  components: {AdminIndex, HeaderAdmin},
  data:()=>({
    accountData:{
      nama:'',
      email:'',
      role:''
    },
  }),
  methods:{
    getUserInformation(){
      let userdata = this.$store.getters.getUserInfo;
      if(userdata != null){
        this.accountData.nama = userdata.data.fullname;
        this.accountData.email = userdata.data.email;
        this.accountData.role = userdata.data.userRole;
        if(this.accountData.role!==3){
          router.push('/home');
        }
      }else{
        router.push('/login');
      }
    }
  },
  beforeMount() {
    this.getUserInformation();
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
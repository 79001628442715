<template>
  <Bread-crumb page="Beranda > Pendaftaran > Tanda Konfirmasi"></Bread-crumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pembayaran Tanda Konfirmasi</h3>
        <hr>
        <div class="form-general">
          <div class="row">
            <div class="col-12 text-left">
              <p>Harap bayarkan tanda konfirmasi untuk : </p>
              <div class="table-data-sga">
                <div class="table-responsive">
                  <TableDataAnak :ispembayaran="true" :datapendaftaran="dataPendaftaran" buttontitle="Bayar tanda konfirmasi" routepath="/gemala/psb/bayar-biaya-konfirmasi/" :withbutton=false :withharga="true"></TableDataAnak>
                </div>
              </div>
              <hr>
              <p v-if="total>0">Anda akan dialihkan ke laman pembayaran tanda konfirmasi melalui <em>payment gateway</em>
                E2Pay sebesar <strong>{{totalvalue}} ({{terbilang}})</strong>
              </p>
              <div class="col-12 spacer-field-sga">
                <LoadSpinner v-if="loading"></LoadSpinner>
              </div>
            </div>
          </div>
          <div class="row" v-if="total>0">
            <div class="col-12" v-if="messagetrx">
              <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
              <AlertDanger v-else :message="messagetrx"></AlertDanger>
            </div>
            <div v-if="successtrx" class="col-12 spacer-field-sga">
              <span>{{messagetrx}}</span>
            </div>
            <div v-else class="col-12 spacer-field-sga">
              <ButtonLoading v-if="loading"></ButtonLoading>
              <a v-else @click="doConfirmPayment" class="btn btn-md btn-success text-white"><i
                  class="fa-solid fa-caret-right"></i> Lakukan pembayaran </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableDataAnak from "@/components/views/psb/part/TableDataAnak";
import DashboardServices from "@/services/dashboard.services";
import useValidate from '@vuelidate/core'
import {numeric, required} from "@vuelidate/validators";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import router from "@/router";
import ButtonLoading from "@/components/ButtonLoading";
export default {
  name: "PagePembayaranBiayaKonfirmasi",
  components: {ButtonLoading, TableDataAnak,AlertSuccess,AlertDanger},
  data: () => ({
    v$: useValidate(),
    dataPendaftaran:[],
    dataPembayaran:[],
    channelPembayaran:[],
    loading: false,
    total:0,
    totalvalue:'',
    terbilang:'',
    invid: '',
    successtrx: false,
    message:'',
    messagetrx:'',
    dataFormulir: {
      data: [],
      jenisbayar:'18'
    }
  }),
  validations() {
    return {
      dataFormulir: {
        jenisbayar: {required,numeric},
      }
    }
  },
  methods:{
    updatePembayaran(value){
      this.dataFormulir.jenisbayar = value;
    },
    async doConfirmPayment(){
      const result = await this.v$.dataFormulir.$validate();
      if(result){
        this.loading = true;
        this.$store.commit('loading',this.loading);
        this.dataFormulir.data = this.dataPendaftaran;
        let data = this.dataFormulir;
        let path = '/dashboard/doPayment';
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.message = response.data.message;
              if(response.data.status===200){
                this.successtrx = true;
                this.messagetrx = response.data.message;
                this.dataPembayaran = this.response.data;
                if(this.dataPembayaran.Status==='SUCCESS'){
                  let checkouturl = this.dataPembayaran.CheckoutURL;
                  //window.open(checkouturl, '_blank');
                  window.open(checkouturl, '_self');
                }
              }else {
                this.success = false;
                if(response.data.status===401){
                  router.push('/login');
                }else{
                  this.successtrx = false;
                  this.messagetrx = response.data.message;
                }
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.successtrx = false;
        this.messagetrx = 'Silahkan isi formulir dengan benar';
      }
    },
    getDataPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {id:this.invid};
      let path = '/dashboard/getDataBiayaKonfirmasi';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data.data;
              this.total = this.response.data.total;
              this.totalvalue = this.response.data.totalvalue;
              this.terbilang = this.response.data.terbilang;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getChannelPembayaran(){
      this.loading = true;
      let data = {"paramname":"metode-bayar"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.channelPembayaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    this.getDataPendaftaran();
    this.getChannelPembayaran();
  },
  watch:{
    '$route.params.id':{
      immediate: true,
      deep: true,
      handler(){
        this.invid = this.$route.params.id;
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <BreadCrumb page="Beranda > Pendaftaran > Isi Formulir"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pengisian Formulir Penerimaan Siswa Baru (PSB) Sekolah Gemala Ananda
        </h3>
        <hr>
        <div class="form-general">
          <div class="row">
            <div class="col-12 text-left">
              <p>Anda dapat mulai mengisi formulir lengkap untuk proses pendaftaran ananda.</p>
              <h4>Panduan Umum</h4>
              <div class="notes-notification-sga" style="margin-top:20px; margin-bottom:20px;">
                <p><strong>PENTING</strong><br></p>
                <ul>
                  <li>Harap <strong>tidak</strong> menggunakan telepon genggam saat mengisi formulir.</li>
                  <li>Gunakan <em>laptop</em> atau <em>PC</em>.</li>
                  <li>Gunakan salah satu <em>browser</em> berikut: Google Chrome (Windows/Mac OS), Mozilla Firefox (Windows/Mac OS) atau Microsoft Edge. </li>
                </ul>
                <p></p>
              </div>
              <ul>
                <li>Formulir secara berkala akan menyimpan otomatis data yang sudah Anda masukkan.</li>
                <li>Setelah menyelesaikan satu bagian formulir secara lengkap, Anda dapat melanjutkan ke
                  tahapan formulir selanjutnya.
                </li>
                <li>Anda dapat kembali sewaktu-waktu untuk memeriksa bagian formulir yang sebelumnya
                  telah Anda isi.
                </li>
                <li>Berkas pendaftaran terdiri dari:
                  <ul>
                    <li>formulir pendaftaran</li>
                    <li>catatan pribadi anak</li>
                    <li>formulir kesehatan</li>
                    <li>surat pernyataan orang tua &lt;<a
                        href="https://sa.gemalaananda.sch.id/file/SuratPernyataan.pdf"
                        target="_blank" rel="noopener">Unduh form di sini</a>&gt;
                    </li>
                    <li>persetujuan ketentuan umum &lt;<a
                        href="https://sa.gemalaananda.sch.id/file/KetentuanUmum.pdf"
                        target="_blank" rel="noopener">Unduh form di sini</a>&gt;
                    </li>
                    <li>surat pengantar untuk dokter &lt;<a
                        href="https://sa.gemalaananda.sch.id/file/surat_pengantar_dokter.pdf"
                        target="_blank">Unduh surat di sini</a>&gt;
                    </li>
                    <li>dokumen pendukung</li>
                    <li>formulir kendaraan</li>
                  </ul>
                </li>
                <li><strong>Dokumen pendukung yang perlu disiapkan adalah sbb.</strong>
                  <ul>
                    <li>pas foto ananda berukuran 3 x 4 cm</li>
                    <li>foto ananda (seluruh badan) ukuran <em>postcard</em></li>
                    <li>foto keluarga ukuran <em>postcard</em></li>
                    <li>akta kelahiran</li>
                    <li>kartu keluarga</li>
                    <li>rapor TK A atau hasil gambar dan tulisan ananda (bila ananda tidak
                      bersekolah di TK)
                    </li>
                    <li>tangkapan layar dari surel “Pendaftaran SD Gemala Ananda Tahun Ajaran
                      2023/2024” yang berisi informasi nomor rekening maya (<em>virtual
                        account</em>).
                    </li>
                  </ul>
                </li>
                <li><strong>Langkah pengisian Form Kesehatan	  </strong>
                  <ol>
                    <li>Orang tua mengisi Formulir Kesehatan. </li>
                    <li>Cetak form kesehatan tersebut.	  </li>
                    <li>Unduh dan cetak surat pengantar untuk dokter, dan tuliskan nama dokter yang biasa menangani ananda.	  </li>
                    <li>Silakan konsultasi ke dokter tsb., dengan membawa form dan surat pengantar tersebut. Minta dokter untuk:
                      <ul>
                        <li> memverifikasi data kesehatan dan membubuhkan paraf pada setiap halaman,	        </li>
                        <li> melakukan pemeriksaan kesehatan ananda,	        </li>
                        <li>mengisi formulir bagian Verifikasi Dokter.	        </li>
                      </ul>
                    </li>
                    <li>Masuk kembali ke Sistem PSB SGA
                      <ul>
                        <li>Sesuaikan isi form dengan penilaian dan rekomendasi yang dituliskan dokter dalam form cetak.	        </li>
                        <li>Tuliskan nama lengkap dokter dan alamat praktiknya.	        </li>
                        <li>Pindai (scan) keseluruhan dokumen dan unggah ke sistem.	        </li>
                        <li>Jangan lupa klik “simpan”. </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li><strong>Catatan penting</strong>
                  <ul>
                    <li>Bila Anda
                      melakukan&nbsp;<strong><em>refresh/reload</em></strong><em>&nbsp;</em>halaman,
                      data berupa isian dan pilihan tetap akan tersimpan, dengan catatan Anda telah menekan tombol simpan yang tersedia di bawah.
                    </li>
                    <li>Bila Anda melakukan pendaftaran dari satu perangkat komputer atau telepon
                      genggam, data hanya akan tersimpan pada perangkat tersebut.
                    </li>
                    <li>Bila Anda telah menyelesaikan formulir terakhir dan tombol “kirim” belum
                      aktif, harap periksa kembali formulir sebelumnya dan pastikan berkas-berkas
                      dan data yang diperlukan telah dilengkapi.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TableDataAnak :ispembayaran="false" :datapendaftaran="dataPendaftaran" buttontitle="Isi formulir" routepath="/gemala/psb/isi/" :withbutton=true></TableDataAnak>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import TableDataAnak from "@/components/views/psb/part/TableDataAnak";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "PagePanduan",
  components: {TableDataAnak, BreadCrumb},
  data: () => ({
    dataPendaftaran:[],
    loading: false
  }),
  methods:{
    getDataPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getDataPendaftaran';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  beforeMount() {
    this.getDataPendaftaran();
  }
}
</script>

<style scoped>

</style>
<template>
  <Bread-crumb page="Beranda > Pendaftaran > Tahun Ajaran > Lihat Tahun Ajaran"></Bread-crumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Daftar Tahun Ajaran</h3>
        <Toolbar class="mb-4">
          <template #start>
            <Button label="Tambah" icon="pi pi-plus" size="small" severity="success" class="mr-2" @click="openNew"/>
          </template>
          <template #end>
            <Button label="Export" icon="pi pi-upload" severity="help" @click="exportCSV($event)"  />
          </template>
        </Toolbar>
        <div class="card">
          <DataTable ref="dt"
                     :value="dataPeriode"
                     v-model:selection="selectedPeriode"
                     :loading="loading"
                     :paginator="true"
                     :rows="10"
                     :filters="filters"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Tampil {first} dari {last} of {totalRecords} data periode"
                     :globalFilterFields="['tahun_ajaran', 'statusinfo']"
                     class="p-datatable-sm"
                     stripedRows
          >
            <template #header>
              <div class="flex justify-content-end">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                </span>
              </div>
            </template>
            <template #paginatorstart>
              <Button type="button" icon="pi pi-refresh" text @click="getDataPeriode" />
            </template>
            <template #paginatorend>
            </template>
            <template #empty> Tidak ada data. </template>
            <template #loading> Mengambil data. Silakan tunggu. </template>
            <Column field="tahun_ajaran" header="Tahun Ajaran" sortable style="min-width:12rem"></Column>
            <Column field="statusinfo" header="Status" sortable style="min-width:16rem"></Column>
            <Column :exportable="false" header="Action"  style="min-width:8rem">
              <template #body="item">
                <Button icon="pi pi-pencil" size="small" class="mr-2" @click="viewEdit(item)"/>
              </template>
            </Column>
          </DataTable>
        </div>
        <Dialog v-model:visible="view.tambah" :style="{width: '600px'}" header="Tambah Data" :modal="true" class="p-fluid">
          <PageParameterTahunAjaran v-if="view.tambah" ></PageParameterTahunAjaran>
        </Dialog>
        <Dialog v-model:visible="view.edit" :style="{width: '600px'}" header="Edit Data" :modal="true" class="p-fluid">
          <PageParameterTahunAjaran v-if="view.edit" :dataPeriode="editPeriode"></PageParameterTahunAjaran>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>

import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import DashboardServices from "@/services/dashboard.services";
import BreadCrumb from "@/components/BreadCrumb";
import tools from "@/js/js.function.tools";
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import useValidate from '@vuelidate/core'
import {required, numeric} from '@vuelidate/validators'
import PageParameterTahunAjaran from "@/components/viewadmin/tahunajaran/PageParameterTahunAjaran";
import {FilterMatchMode} from "primevue/api";
export default {
  name: "PageViewTahunAjaran",
  components:{
    PageParameterTahunAjaran,
    BreadCrumb,Toolbar,Button,DataTable,Column,Dialog,InputText},
  data:()=>({
    v$: useValidate(),
    loading: false,
    response: '',
    message:'',
    success:false,
    successtrx: false,
    dataPeriode:[],
    selectedPeriode:[],
    editPeriode:[],
    view:{
      tambah: false,
      edit: false,
    },
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    messagetrx:'',
    error:'',
    dataParameter:{
      tahunajaranpsb:'',
      tahunajaransaatini:'',
      kapasitas:{
        kelas1:'',
        kelas1pindahan:'',
        kelas2:'',
        kelas3:'',
        kelas4:'',
        kelas5:'',
        kelas6:'',
      },
      maxberkas:'',
      biaya:{
        formulir:'',
        konfirmasi:'',
      },
      waktubuka:{
        tanggal:'',
        waktu:''
      },
      waktututup:{
        tanggal:'',
        waktu:''
      },
      tahunajaranaktif:false
    }
  }),
  methods:{
    onInput(evt, max) {
      tools.inputNumericOnly(evt, max);
    },
    viewEdit(item){
      this.editPeriode = item;
      this.view.edit = true;
    },
    openNew() {
      //this.product = {};
      //this.submitted = false;
      this.view.tambah = true;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    generateLink(item){
      let id = item.periode_id;
      return "/admin/edit-parameter/setup-tahunajaran/"+id;
    },
    getDataPeriode(){
      this.loading = true;
      let data = {};
      let path = '/dashboard/getDataPeriode';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPeriode = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  validations() {
    return {
      dataParameter:{
        tahunajaranpsb:{required},
        tahunajaransaatini:{required},
        maxberkas: {required},
        kapasitas:{
          kelas1:{required,numeric},
          kelas2:{required,numeric},
          kelas3:{required,numeric},
          kelas4:{required,numeric},
          kelas5:{required,numeric},
          kelas6:{required,numeric},
        },
        biaya:{
          formulir:{required,numeric},
          konfirmasi:{required,numeric},
        },
        waktubuka:{
          tanggal: {required},
          waktu: {required}
        },
        waktututup:{
          tanggal: {required},
          waktu: {required}
        },
        tahunajaranaktif:{required}
      }
    }
  },
  mounted() {
    this.getDataPeriode();
  }
}
</script>

<style scoped>

</style>
<template>
  <BreadCrumb page="Beranda > Pendaftaran > Isi formulir Berasa"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pengisian Formulir Berasa</h3>
        <hr>
        <div class="row">
          <div class="col-12">
            <div class="notes-notification-sga">
              <p><strong>Catatan</strong>
              <ul>
                <li>
                  Waktu pengisian formulir adalah <strong>2 jam </strong>sejak tombol dimulai ditekan
                </li>
                <li>Formulir akan otomatis terkirim setelah batas waktu terlewati</li>
              </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-12">
            <div class="notes-notification-sga bg-light">
              <h4><strong>Panduan Pelaksanaan</strong>
              </h4>
              <ul>
                <li>Ceritakan nilai dan peristiwa penting dalam       kehidupan Anda sebagai orang tua.</li>
                <li>Terdapat empat topik pertanyaan yang perlu Anda       refleksikan: </li>
                <ul type="circle">
                  <li>dua butir terkait nilai-nilai yang penting bagi        Anda, dan </li>
                  <li>dua butir tentang peristiwa penting dalam        perjalanan Anda sebagai orang tua.</li>
                </ul>
                <li>Pada tiap topik diberikan pertanyaan/permintaan       pembuka.<strong> Bacalah dengan saksama</strong>.</li>
                <li>Nilai dan peristiwa yang akan Anda ceritakan       adalah PERISTIWA PENTING YANG BETUL-BETUL TERJADI DALAM KURUN WAKTU SATU       TAHUN TERAKHIR, DI MANA ANDA TERLIBAT PENUH DALAM PERISTIWA TERSEBUT.</li>
                <li>Tidak ada peristiwa yang buruk atau baik, benar       atau salah. Sebuah peristiwa adalah sebuah momen yang telah terjadi.</li>
                <li><u>Kedua orang tua </u>wajib melaksanakan kegiatan ini secara <u>terpisah/sendiri-sendiri</u>.</li>
                <li>Kegiatan wawancara orang tua baru bisa       dilaksanakan bila kedua orang tua sudah mengirimkan refleksinya melalui       sistem daring ini.</li>
                <li>Aktivitas refleksi perlu dilakukan dalam satu kali       penulisan sampai tuntas (<em>one sitting</em>). Menurut pengalaman,       diperlukan waktu 90 menit, tetapi Anda diberi batas waktu pengerjaan <u>maksimal       120 menit</u>. Waktu dihitung ketika Anda masuk ke laman form refleksi.</li>
                <li>Disediakan contoh pengisian, yang disertai dengan       keterangan. Semoga menjadi ilustrasi yang berguna dan mempermudah proses       penulisan refleksi dan cerita pengalaman Anda sendiri. Waktu membaca       instruksi dan contoh tidak termasuk dalam alokasi waktu 120 menit.</li>
                <li>Disarankan untuk mengerjakan kegiatan refleksi ini       dengan menggunakan komputer/laptop, tidak menggunakan ponsel (HP), dan       tidak membuka banyak halaman yang lain, untuk mencegah hilangnya data atau       gagal kirim.</li>
                <li>Untuk back-up, Anda dapat menyalin (copy &amp;       paste) hasil refleksi Anda sebelum mengirimkannya (submit).</li>
                <li>Bila waktu 120 menit habis, sistem akan secara       otomatis me-reset formulir. Pastikan Anda sudah mengirimkan (submit) form       refleksi Anda. Bila belum sempat mengirimkannya, Anda perlu mengulang       proses dari awal dengan menggunakan akun surel yang berbeda. Karenanya       mohon memperhatikan waktu.</li>
              </ul>
              <p></p>

              <h4><strong>Contoh dengan keterangan

              </strong>
              </h4>


              <table class="table table-striped ">
                <thead>
                <tr>
                  <th scope="col">Pertanyaan/permintaan</th>
                  <th scope="col">Keterangan tentang maksud pertanyaan/permintaan</th>

                </tr>
                </thead>

                <tbody>

                <tr>
                  <td>Judul peristiwa</td>
                  <td><span style="color: #3366ff;">Beri judul dari peristiwa yang akan Anda ceritakan, dengan diawali nama Anda – agar Anda memahami bahwa ini adalah peristiwa Anda dan Anda terlibat penuh di dalam peristiwa itu.&nbsp;</span><p></p>
                    <p><span style="color: #3366ff;">Contoh: nama Anda adalah EKA.&nbsp; Karenanya judul peristiwanya adalah:</span></p>
                    <p><em>EKA berhasil meng-goal-kan proposal </em>playground<em> di kompleks perumahan</em></p>
                  </td>

                </tr>

                <tr>
                  <td>Waktu dan durasi peristiwa</td>
                  <td><span style="color: #3366ff;">Tuliskan perkiraan waktunya. Tidak perlu terlalu tepat, namun jelas bulan dan tahunnya.</span><br><br>
                    <span style="color: #3366ff;">Contoh:</span> <em>&nbsp;</em><em>Awal Agustus – akhir September 2019</em>
                  </td>

                </tr>

                <tr>
                  <td>Latar belakang peristiwa</td>
                  <td><span style="color: #3366ff;">Ini adalah tentang hal yang menyebabkan peristiwa yang Anda ceritakan itu terjadi.&nbsp; Misalnya:</span><p></p>
                    <p><em>Anak-anak usia TK kebawah belum memiliki arena bermain yang layak. Selama ini anak-anak (yang jumlahnya sekitar 15-20 kanak-kanak) bermain di halaman rumah-rumah warga dan ini bahaya. Sementara kita punya tanah fasos-fasum yang belum dimanfaatkan.</em></p>
                  </td>

                </tr>

                <tr>
                  <td>Orang-orang yang terlibat	</td>
                  <td><span style="color: #3366ff;">Ini adalah tentang keterlibatan Anda dan orang lain dalam peristiwa ini. Misalnya:</span><p></p>
                    <p><em>Saya, ketua RT, ketua RW dan dua orang ibu yang sepikiran</em></p>
                  </td>

                </tr>

                <tr>
                  <td>Hasil akhir dari peristiwa ini</td>
                  <td><span style="color: #3366ff;">Ini adalah tentang hasil akhir dari peristiwa ini. Contoh:</span><p></p>
                    <p><em>Pembangunan </em>playground<em> disetujui dan akan segera dibangun.&nbsp; </em></p>
                  </td>

                </tr>
                <tr>
                  <td>Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk mencapainya. <br><br><em><strong>Catatan</strong>:</em>
                    <ul>
                      <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan hal tersebut.

                      </li>
                      <li>minimal 350 kata		      </li>
                      <li>maksimal 500 kata </li>
                    </ul>

                  </td>
                  <td><span style="color: #3366ff;">Ini adalah bagian paling penting, dimana Anda menceritakan langkah-langkah yang Anda lakukan sejak awal Anda terlibat dalam peristiwa ini, sampai akhir dari peristiwa ini.&nbsp; Umumnya langkah ini berkisar antara 3-6 langkah, tetapi tidak dibatasi, mengingat setiap peristiwa tentu berbeda.</span><p></p>
                    <p><em>Contoh:</em></p>
                    <p><em>Awalnya adalah salah satu anak (putranya bu Nisa) terserempet sepedah seorang bapak yang melintas. Tidak parah lukanya, tapi perlu dijahit pelipis kanannya dan membuat ibunya luar biasa panik dan marah.</em></p>
                    <p><em>Saya jadi terpikir: kan kita punya tanah fasos-fasum yang lumayan luas. Sekitar 250 meter. Kan bisa kita buat arena bermain atau </em>playground<em> gitu yang layak buat anak-anak. Jadi mereka bebas, leluasa bermain.</em></p>
                    <p><em>Ndak pikir panjang, besoknya saya menghadap ke pak RT melaporkan peristiwa keserempet itu (kebetulan saya lihat peristiwanya) dan mengusulkan untuk melihat fasos-fasum itu. Pak RT setuju dan kami berdua pergi ke lahan itu.&nbsp; </em></p>
                    <p><em>Lahan itu ditumbuhi rumput liar yang tinggi-tinggi dan satu pohon rambutan yang kelihatannya belum pernah berbuah. Memang tidak bisa dipakai untuk bermain. Kotor dan jorok.</em></p>
                    <p><em>Saya sampaikan ide-ide saya kepada pak RT tentang gambaran </em>playground<em> itu.&nbsp; Pak Rt kelihatan senang sekali – mungkin karena dia punya cucu kembar yang umurnya hampir 3 tahun. Beliau bilang “Silakan pak, dibuat proposalnya, nanti saya bantu ngomong sama pak RW”</em></p>
                    <p><em>Nah, mumpung masih </em>long weekend<em> dan istri sedang pulang kampung nengok mertua, saya buat proposalnya. Saya kerjakan sampai malam. Saya buat gambaran besar dari </em>playground<em> itu (untungnya saya biasa menggambar). Saya juga berdiskusi dengan kepala bagian pertamanan di kompleks tentang perkiraan harga dan jenis tanaman dan alat-alat permainan yang menarik buat anak-anak.&nbsp; Nah, ternyata biayanya lumayan besar.&nbsp; </em></p>
                    <p><em>Tapi saya tidak hilang akal. Saya dekati satu persatu para orang tua yang punya anak kecil dan saya ajak untuk “patungan” dalam pembiayaan. Sebagian besar setuju, walaupun ada juga yang bilang “Lah, ngapain. Itu kan tanggung jawab RW dan pertamanan!” – yaudah, yang itu tidak saya </em>approach<em>.</em></p>
                    <p><em>Terakhir, proposal saya bereskan dan di bagian pendanaan, saya tulis jelas tentang biaya yang dibutuhkan, dan berapa orang tua yang mau donasi sehingga bisa meng-</em>cover<em> 60% dari biaya yang dibutuhkan. Kemudian saya e-mail kepada pak RW.</em></p>
                    <p><em>Dua hari kemudian saya dipanggil untuk pemaparan. Saya ceritakan tentang serempetan itu, dan prediksi kecelakaan akan berulang bila </em>playground<em> tidak dibuat juga. Anak- anak semakin banyak jumlahnya. Dan orang tua rata-rata bekerja. Pak RW menyambut proposal itu. Dan selanjutnya saya tidak terlibat lagi, karena dilimpahkan ke ketua bagian pertamanan.</em></p>
                    <p><em>Terakhir saya ditelpon oleh pak RW dan menyampaikan bahwa </em>playground<em> akan segera dibangun. Saya senang sekali</em></p>
                    <p><span style="color: #3366ff;">Anda bisa menggunakan <em>bullet points</em>, bisa menggunakan cara lain. Yang penting pembaca bisa memahami secara lengkap langkah-langkah PENTING yang Anda lakukan sampai Anda mencapai hasil akhirnya.</span></p><p></p>
                  </td>

                </tr>

                <tr>
                  <td>Apa perasaan Anda ketika sampai pada hasil akhir tersebut? Mengapa?</td>
                  <td><span style="color: #3366ff;">Ini berisi tentang perasaan Anda atas hasil akhir yang Anda capai dan apa yang membuat Anda merasa begitu. Contoh:</span><p></p>
                    <p><em>Senang, karena bisa membantu orang tua di RT saya untuk merasa tenang karena </em>playground<em> akan segera dibangun</em></p>
                  </td>

                </tr>
                <tr>
                  <td>Pelajaran/hikmah apa yang Anda petik dari peristiwa ini?	</td>
                  <td><span style="color: #3366ff;">Ini adalah catatan refleksi Anda pribadi tentang peristiwa tersebut.</span><p></p>
                    <p><em>Tidak perlu menunggu ada peristiwa untuk memutuskan sesuatu.&nbsp; Mosok harus menunggu ada anak yang keserempet sepedah untuk membuat </em>playground<em>?</em></p>
                  </td>

                </tr>
                </tbody>


              </table>

              <h4><strong>Contoh tanpa keterangan

              </strong>
              </h4>


              <table class="table table-striped ">
                <thead>
                <tr>
                  <th scope="col">Pertanyaan/permintaan</th>
                  <th scope="col">Keterangan tentang maksud pertanyaan/permintaan</th>

                </tr>
                </thead>

                <tbody>
                <tr>
                  <td>Judul peristiwa</td>
                  <td>
                    <p><em>EKA berhasil meng-goal-kan proposal </em>playground<em> di kompleks perumahan</em></p>
                  </td>
                </tr>
                <tr>
                  <td>Waktu dan durasi peristiwa</td>
                  <td><em>Awal Agustus – akhir September 2019</em>
                  </td>
                </tr>
                <tr>
                  <td>Latar belakang peristiwa</td>
                  <td>
                    <p><em>Anak-anak usia TK kebawah belum memiliki arena bermain yang layak. Selama ini anak-anak (yang jumlahnya sekitar 15-20 kanak-kanak) bermain di halaman rumah-rumah warga dan ini bahaya. Sementara kita punya tanah fasos-fasum yang belum dimanfaatkan.</em></p>
                  </td>
                </tr>
                <tr>
                  <td>Orang-orang yang terlibat	</td>
                  <td>
                    <p><em>Saya, ketua RT, ketua RW dan dua orang ibu yang sepikiran</em></p>
                  </td>
                </tr>
                <tr>
                  <td>Hasil akhir dari peristiwa ini</td>
                  <td>
                    <p><em>Pembangunan </em>playground<em> disetujui dan akan segera dibangun.&nbsp; </em></p>
                  </td>
                </tr>
                <tr>
                  <td>Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk mencapainya. <br><br><em><strong>Catatan</strong>:</em>
                    <ul>
                      <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan hal tersebut.
                      </li>
                      <li>minimal 350 kata		      </li>
                      <li>maksimal 500 kata </li>
                    </ul>
                  </td>
                  <td>
                    <p><em>Contoh:</em></p>
                    <p><em>Awalnya adalah salah satu anak (putranya bu Nisa) terserempet sepedah seorang bapak yang melintas. Tidak parah lukanya, tapi perlu dijahit pelipis kanannya dan membuat ibunya luar biasa panik dan marah.</em></p>
                    <p><em>Saya jadi terpikir: kan kita punya tanah fasos-fasum yang lumayan luas. Sekitar 250 meter. Kan bisa kita buat arena bermain atau </em>playground<em> gitu yang layak buat anak-anak. Jadi mereka bebas, leluasa bermain.</em></p>
                    <p><em>Ndak pikir panjang, besoknya saya menghadap ke pak RT melaporkan peristiwa keserempet itu (kebetulan saya lihat peristiwanya) dan mengusulkan untuk melihat fasos-fasum itu. Pak RT setuju dan kami berdua pergi ke lahan itu.&nbsp; </em></p>
                    <p><em>Lahan itu ditumbuhi rumput liar yang tinggi-tinggi dan satu pohon rambutan yang kelihatannya belum pernah berbuah. Memang tidak bisa dipakai untuk bermain. Kotor dan jorok.</em></p>
                    <p><em>Saya sampaikan ide-ide saya kepada pak RT tentang gambaran </em>playground<em> itu.&nbsp; Pak Rt kelihatan senang sekali – mungkin karena dia punya cucu kembar yang umurnya hampir 3 tahun. Beliau bilang “Silakan pak, dibuat proposalnya, nanti saya bantu ngomong sama pak RW”</em></p>
                    <p><em>Nah, mumpung masih </em>long weekend<em> dan istri sedang pulang kampung nengok mertua, saya buat proposalnya. Saya kerjakan sampai malam. Saya buat gambaran besar dari </em>playground<em> itu (untungnya saya biasa menggambar). Saya juga berdiskusi dengan kepala bagian pertamanan di kompleks tentang perkiraan harga dan jenis tanaman dan alat-alat permainan yang menarik buat anak-anak.&nbsp; Nah, ternyata biayanya lumayan besar.&nbsp; </em></p>
                    <p><em>Tapi saya tidak hilang akal. Saya dekati satu persatu para orang tua yang punya anak kecil dan saya ajak untuk “patungan” dalam pembiayaan. Sebagian besar setuju, walaupun ada juga yang bilang “Lah, ngapain. Itu kan tanggung jawab RW dan pertamanan!” – yaudah, yang itu tidak saya </em>approach<em>.</em></p>
                    <p><em>Terakhir, proposal saya bereskan dan di bagian pendanaan, saya tulis jelas tentang biaya yang dibutuhkan, dan berapa orang tua yang mau donasi sehingga bisa meng-</em>cover<em> 60% dari biaya yang dibutuhkan. Kemudian saya e-mail kepada pak RW.</em></p>
                    <p><em>Dua hari kemudian saya dipanggil untuk pemaparan. Saya ceritakan tentang serempetan itu, dan prediksi kecelakaan akan berulang bila </em>playground<em> tidak dibuat juga. Anak- anak semakin banyak jumlahnya. Dan orang tua rata-rata bekerja. Pak RW menyambut proposal itu. Dan selanjutnya saya tidak terlibat lagi, karena dilimpahkan ke ketua bagian pertamanan.</em></p>
                    <p><em>Terakhir saya ditelpon oleh pak RW dan menyampaikan bahwa </em>playground<em> akan segera dibangun. Saya senang sekali</em></p>
                  </td>
                </tr>
                <tr>
                  <td>Apa perasaan Anda ketika sampai pada hasil akhir tersebut? Mengapa?</td>
                  <td>
                    <p><em>Senang, karena bisa membantu orang tua di RT saya untuk merasa tenang karena </em>playground<em> akan segera dibangun</em></p>
                  </td>
                </tr>
                <tr>
                  <td>Pelajaran/hikmah apa yang Anda petik dari peristiwa ini?	</td>
                  <td>
                    <p><em>Tidak perlu menunggu ada peristiwa untuk memutuskan sesuatu.&nbsp; Mosok harus menunggu ada anak yang keserempet sepedah untuk membuat </em>playground<em>?</em></p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div></div>
        </div>
        <div class="row" v-if="showtombol">
          <div class="col-12 button-area-sga text-left" style="margin-bottom: 25px;">
            <button v-if="!startisi" @click="startIsiBerasa" class="btn btn-lg btn-success"
               type="button"><i class="fa-solid fa-caret-right"></i> Isi Formulir Berasa</button>
          </div>
        </div>
        <div class="row">
          <LoadSpinner v-if="loadingdata"></LoadSpinner>
          <div class="col-12" v-else>
            <div class="notes-notification-sga2">
              <div class="row">
                <div class="col-md-3 my-auto"> No formulir:<br><strong>{{dataPendaftaran.inv_child}}</strong></div>
                <div class="col-md-3 my-auto"> Nama Ananda:<br><strong>{{dataPendaftaran.anak_nama}}</strong></div>
                <div class="col-md-3 my-auto"> Nama Orang Tua:<br><strong>{{dataPendaftaran.nama_ortu}}</strong></div>
                <div class="col-md-3 my-auto">Status hubungan<br><strong>{{dataPendaftaran.tipe_pengisi}}</strong></div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="!formenable">
            <div class="notes-notification-sga2">
              <div class="row">
                <div class="col-md-3 my-auto"> Form berasa sudah terkirim.</div>
              </div>
            </div>
          </div>
        </div>
        <form class="needs-validation" novalidate v-if="formenable">
          <div class="form-general">
            <div class="row">
              <div class="col-12">
                <div class="notes-notification-sga text-center"><p>Sisa waktu</p>
                  <vue-countdown :time="time" :interval="100" v-slot="{ hours, minutes, seconds, milliseconds }" @end="submitTanpaValidasi">
                    <h3 style="color: red;"> {{ hours }} : {{ minutes }} : {{ seconds }}.{{ Math.floor(milliseconds / 100) }}</h3>
                  </vue-countdown>
                </div>
              </div>
            </div>
          </div>
          <div class="form-general" v-if="startisi">
            <div class="group-sga">
              <div class="row">
                <div class="col-12"><h4>Topik 1</h4></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <normal-label-nilai>Nilai apakah yang ditanamkan orang tua Anda kepada Anda, yang
                    sekarang Anda terapkan dalam kehidupan sehari-hari?
                  </normal-label-nilai>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikSatu.jawaban1.$error?'text-danger':''">Nilai</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikSatu.jawaban1" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikSatu.jawaban2.$error?'text-danger':''">Mengapa nilai tersebut Anda anggap penting untuk diterapkan dalam kehidupan Anda?</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikSatu.jawaban2" class="form-control textarea-sga" rows="4" required></textarea>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikSatu.jawaban3.$error?'text-danger':''">Ceritakan satu peristiwa spesifik dalam
                  kurun waktu satu tahun terakhir, di mana Anda menerapkan nilai tersebut dalam
                  kehidupan sehari-hari.</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikSatu.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
                </div>
              </div>
            </div>
            <hr>
            <div class="group-sga">
              <div class="row">
                <div class="col-12"><h4>Topik 2</h4></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <normal-label-nilai>Apakah nilai lain yang menurut Anda pribadi juga penting dalam
                    mendidik anak?
                  </normal-label-nilai>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikDua.jawaban1.$error?'text-danger':''">Nilai</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikDua.jawaban1" class="w-100 form-control" required></div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikDua.jawaban2.$error?'text-danger':''">Mengapa nilai tersebut Anda anggap penting
                  untuk diterapkan dalam kehidupan Anda?</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikDua.jawaban2" class="form-control textarea-sga" rows="4" required></textarea>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikDua.jawaban3.$error?'text-danger':''">Ceritakan satu peristiwa spesifik dalam
                  kurun waktu satu tahun terakhir, di mana Anda menerapkan nilai tersebut dalam
                  kehidupan sehari-hari.</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikDua.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
                </div>
              </div>
            </div>
            <hr>
            <div class="group-sga">
              <div class="row">
                <div class="col-12"><h4>Topik 3</h4></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <normal-label-nilai> Dalam kurun waktu satu tahun ini, adakah peristiwa spesifik di
                    mana Anda mengalah kepada orang lain atau kesabaran Anda diuji? Apa
                    peristiwanya, apa yang Anda lakukan, dan bagaimana hasilnya? Ceritakanlah dengan
                    menggunakan format di bawah ini.
                  </normal-label-nilai>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban1.$error?'text-danger':''">Judul peristiwa</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikTiga.jawaban1" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban2.$error?'text-danger':''">Waktu dan durasi peristiwa</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikTiga.jawaban2" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban3.$error?'text-danger':''">Latar belakang peristiwa</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikTiga.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban4.$error?'text-danger':''">Orang-orang yang terlibat</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikTiga.jawaban4" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban5.$error?'text-danger':''">Hasil akhir dari peristiwa ini</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikTiga.jawaban5" class="w-100 form-control" required>
                </div>
                <div class="col-12 spacer-field-sga"></div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban6.$error?'text-danger':''">Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk mencapainya</label>
                  <p style="margin-top: 15px;"><strong>Catatan:</strong></p>
                  <ul>
                    <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan
                      hal tersebut
                    </li>
                    <li> minimal 350 kata</li>
                    <li> maksimal 500 kata</li>
                  </ul>
                </div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikTiga.jawaban6" class="form-control textarea-sga" rows="9" required></textarea>
                  <p class="text">{{wordsLen(dataBerasa.topikTiga.jawaban6)}}/500</p>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban7.$error?'text-danger':''">Apa perasaan tentang hasilnya? Mengapa?</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikTiga.jawaban7" class="w-100 form-control" required></div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikTiga.jawaban8.$error?'text-danger':''">Pelajaran/hikmah apa yang Anda petik dari peristiwa ini?</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikTiga.jawaban8" class="w-100 form-control" required>
                </div>
              </div>
            </div>
            <hr>
            <div class="group-sga">
              <div class="row">
                <div class="col-12"><h4>Topik 4</h4></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <normal-label-nilai>Dalam kurun waktu satu tahun terakhir ini, pernahkah Anda berada
                    dalam situasi di mana anak Anda menunjukkan masalah/perilaku yang Anda anggap
                    berat? Apa peristiwanya, apa yang Anda lakukan, dan bagaimana hasilnya?
                    Ceritakanlah dengan menggunakan format di bawah ini.
                  </normal-label-nilai>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikEmpat.jawaban1.$error?'text-danger':''">Judul peristiwa</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikEmpat.jawaban1" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikEmpat.jawaban2.$error?'text-danger':''">Waktu dan durasi peristiwa</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikEmpat.jawaban2" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikEmpat.jawaban3.$error?'text-danger':''">Latar belakang peristiwa</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikEmpat.jawaban3" class="form-control textarea-sga" rows="4" required></textarea>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikEmpat.jawaban4.$error?'text-danger':''">Orang-orang yang terlibat</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikEmpat.jawaban4" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikEmpat.jawaban5.$error?'text-danger':''">Hasil akhir dari peristiwa ini</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikEmpat.jawaban5" class="w-100 form-control" required>
                </div>
                <div class="col-12 spacer-field-sga"></div>
                <div class="col-md-4 spacer-field-sga">
                  <label :class="v$.dataBerasa.topikEmpat.jawaban6.$error?'text-danger':''">Secara berurutan, langkah-langkah penting apa yang Anda lakukan untuk
                    mencapainya</label>
                  <p style="margin-top: 15px;"><strong>Catatan:</strong></p>
                  <ul>
                    <li>Pastikan Anda menggunakan kata “saya” ketika Anda sendirilah yang melakukan
                      hal tersebut
                    </li>
                    <li> minimal 350 kata</li>
                    <li> maksimal 500 kata</li>
                  </ul>
                </div>
                <div class="col-md-8 spacer-field-sga">
                  <textarea v-model="dataBerasa.topikEmpat.jawaban6" class="form-control textarea-sga" rows="9" required></textarea>
                  <p class="text">{{wordsLen(dataBerasa.topikEmpat.jawaban6)}}/500</p>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikEmpat.jawaban7.$error?'text-danger':''">Apa perasaan tentang hasilnya? Mengapa?</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikEmpat.jawaban7" class="w-100 form-control" required>
                </div>
                <div class="col-md-4 spacer-field-sga"><label :class="v$.dataBerasa.topikEmpat.jawaban8.$error?'text-danger':''">Pelajaran/hikmah apa yang Anda petik dari peristiwa ini?</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <input type="text" v-model="dataBerasa.topikEmpat.jawaban8" class="w-100 form-control" required>
                </div>
              </div>
            </div>
            <hr>
            <div class="col-12" v-if="messagetrx">
              <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
              <AlertDanger v-else :message="messagetrx"></AlertDanger>
            </div>
            <div class="row">
              <div class="col-md-12" v-if="errorisi">
                <AlertDanger :message="message"></AlertDanger>
              </div>
              <div class="col-12 button-area-sga text-right">
                <ConfirmDialog></ConfirmDialog>
                <button type="button" @click="konfirmasi" class="btn btn-md btn-success">
                  <i class="fa-solid fa-caret-right"></i> Kirim Formulir Berasa
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import VueCountdown from '@chenfengyuan/vue-countdown';
import useValidate from '@vuelidate/core'
import {required, minLength, maxLength} from '@vuelidate/validators'
import AlertDanger from "@/components/AlertDanger";
import AlertSuccess from "@/components/AlertSuccess";
import DashboardServices from "@/services/dashboard.services";
import ConfirmDialog from 'primevue/confirmdialog';
import LoadSpinner from "@/components/LoadSpinner";
export default {
  name: "PageIsiFormulirBerasa",
  components: {LoadSpinner, AlertDanger,AlertSuccess, BreadCrumb,VueCountdown,ConfirmDialog},
  props:{
    token:String
  },
  data() {
    return{
      v$: useValidate(),
      now:'',
      newYear:'',
      time:0,
      showtombol: false,
      formenable: true,
      message:'',
      loading: false,
      loadingdata: false,
      dataStartIsiFormulir:[],
      dataIsiFormulir:[],
      success: false,
      successtrx: false,
      messagetrx:'',
      response:'',
      invid:'',
      startisi: false,
      dataPendaftaran:[],
      errorisi: false,
      dataBerasa:{
        tokenlink:'',
        topikSatu:{
          jawaban1:'',
          jawaban2:'',
          jawaban3:'',
        },
        topikDua:{
          jawaban1:'',
          jawaban2:'',
          jawaban3:'',
        },
        topikTiga:{
          jawaban1:'',
          jawaban2:'',
          jawaban3:'',
          jawaban4:'',
          jawaban5:'',
          jawaban6:'',
          jawaban7:'',
          jawaban8:'',
        },
        topikEmpat:{
          jawaban1:'',
          jawaban2:'',
          jawaban3:'',
          jawaban4:'',
          jawaban5:'',
          jawaban6:'',
          jawaban7:'',
          jawaban8:'',
        }
      }
    }
  },
  validations() {
    return {
      dataBerasa:{
        topikSatu:{
          jawaban1:{required, minLength: minLength(2), maxLength: maxLength(300)},
          jawaban2:{required, minLength: minLength(10)},
          jawaban3:{required},
        },
        topikDua:{
          jawaban1:{required},
          jawaban2:{required},
          jawaban3:{required},
        },
        topikTiga:{
          jawaban1:{required},
          jawaban2:{required},
          jawaban3:{required},
          jawaban4:{required},
          jawaban5:{required},
          jawaban6:{required,minLength: minLength(100)},
          jawaban7:{required},
          jawaban8:{required},
        },
        topikEmpat:{
          jawaban1:{required},
          jawaban2:{required},
          jawaban3:{required},
          jawaban4:{required},
          jawaban5:{required},
          jawaban6:{
            required,minLength: minLength(100)},
          jawaban7:{required},
          jawaban8:{required},
        }
      }
    }
  },
  computed:{
    topik3word(){
      return this.wordsLen(this.dataBerasa.topikTiga.jawaban6)>=350;
    },
    topik4word(){
      return this.wordsLen(this.dataBerasa.topikTiga.jawaban6)>=350;
    }
  },
  methods:{
    startIsiBerasa(){
      let data = {paramname:this.token};
      let path = '/berasa/start/'+this.token;
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            //this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.error = false;
              this.dataStartIsiFormulir = this.response.data;
              this.now = new Date((this.dataStartIsiFormulir.currenttime)*1000);
              this.newYear = new Date((this.dataStartIsiFormulir.endtime)*1000);
              this.time = this.newYear - this.now;
              this.startisi = true;
              this.formenable = true;
            }else {
              this.error = true;
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
      this.startisi = true;
    },
    validate() {
      this.v$.dataBerasa.$validate();
    },
    wordsLen(str) {
      const array = str.trim().split(/\s+/);
      return array.length;
    },
    async konfirmasi(){
      this.errorisi = false;
      const result = await this.v$.dataBerasa.$validate();
      if(result){
        this.$confirm.require({
          message: 'Apakah data yang Anda masukkan sudah benar?',
          header: 'Pengisian Formulir',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Ya',
          rejectLabel: 'Cek kembali',
          defaultFocus:'reject',
          accept: () => {
            this.submitFormBerasa();
          },
          reject: () => {
          }
        });
      }else{
        this.errorisi = true;
        this.success = false;
        this.message = 'Silahkan isi formulir dengan benar';
      }
    },
    submitTanpaValidasi(){
      let data = {token:this.token,method:'submit',formulir: this.dataBerasa};
      let path = '/berasa/submit/'+this.token;
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            if(response.data.status===200){
              this.dataIsiFormulir = this.response.data;
              this.startisi = false;
              this.showtombol = false;
              this.formenable = false;
              this.time = 0;
            }else {
              this.error = true;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    async submitFormBerasa(){
      const result = await this.v$.dataBerasa.$validate();
      if(result){
        this.loading = true;
        this.$store.commit('loading',this.loading);
        let data = {token:this.token,method:'submit',formulir: this.dataBerasa};
        let path = '/berasa/submit/'+this.token;
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.messagetrx = response.data.message;
              if(response.data.status===200){
                this.successtrx = true;
                this.error = false;
                this.dataIsiFormulir = this.response.data;
                this.startisi = false;
                this.showtombol = false;
                this.formenable = false;
                this.time = 0;
              }else {
                this.error = true;
                this.successtrx = false;
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.success = false;
        this.message = 'Silahkan isi formulir dengan benar';
      }
    },
    getDataSiapBerasa(){
      this.loadingdata = true;
      this.$store.commit('loading',this.loadingdata);
      let data = {paramname:this.token};
      let path = '/berasa/getdata/'+this.token;
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data.data;
              let status = this.dataPendaftaran.form_status;
              let formstatus = this.response.data.status
              if(formstatus==='open'){
                this.startisi = true;
                this.showtombol = true;
                this.formenable = true;
              }else if(formstatus==='pending'){
                this.startisi = false;
                this.showtombol = true;
                this.formenable = true;
              }else{
                this.startisi = false;
                this.showtombol = false;
                this.formenable = false;
              }
              if(status==='start'){
                this.now = new Date((this.response.data.currenttime)*1000);
                this.newYear = new Date((this.response.data.endtime)*1000);
                this.time = this.newYear - this.now;
              }
            }else {
              this.success = false;
            }
            this.loadingdata = false;
            this.$store.commit('loading',this.loadingdata);
          }
      );
    }
  },
  mounted() {
    this.getDataSiapBerasa();
  },
}
</script>

<style scoped>

</style>
<template>
  <!--Dokumen pendukung   -->
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <label :class="v$.dataFormulir.dokumen.kelahiran.$error?'text-danger':''"><i class="fa-solid fa-image"></i> Hasil pindai (scan) Akte Kelahiran</label>
      <div class="custom-file">
        <FileLister v-if="files.aktekelahiran.size!=0" :filedata="files.aktekelahiran.list" :num-visible="files.aktekelahiran.size"></FileLister>
        <ProgressBar v-if="isupload1" class="mt-2 mb-2" :value="uploadprogress1" style="height: 10px;font-size: 14px"></ProgressBar>
        <FileUpload v-if="showupload" :class="classupload1" :fileLimit="2" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel1" @before-send="beforeUploadSatu" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg" :maxFileSize="5000000" @progress="onProgressSatu" @upload="onUploadSatu" @select="onSelectSatu">
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
        <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg | Maksimum 2 berkas | Minimum 1 berkas</keterangan-form>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <label :class="v$.dataFormulir.dokumen.kartukeluarga.$error?'text-danger':''"><i class="fa-solid fa-image"></i> Hasil pindai (scan) Kartu Keluarga</label>
      <div class="custom-file">
        <FileLister v-if="files.kartukeluarga.size!=0" :filedata="files.kartukeluarga.list" :num-visible="files.kartukeluarga.size"></FileLister>
        <ProgressBar v-if="isupload2" class="mt-2 mb-2" :value="uploadprogress2" style="height: 10px;font-size: 14px"></ProgressBar>
        <FileUpload v-if="showupload" :class="classupload2" :showUploadButton="false" :showCancelButton="false" :multiple="false" :fileLimit="2" :chooseLabel="uploadLabel2" @before-send="beforeUploadDua" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg" :maxFileSize="5000000" @progress="onProgressDua" @upload="onUploadDua" @select="onSelectDua">
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
        <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg | Maksimum 2 berkas | Minimum 1 berkas</keterangan-form>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <label :class="v$.dataFormulir.dokumen.report.$error?'text-danger':''"><i class="fa-solid fa-image"></i> Hasil pindai (scan) raport TK–A dan TK-B (bila ada) apabila ananda pernah bersekolah di TK. Bila ananda tidak pernah bersekolah di TK, harap unggah hasil karya gambar ananda yang diberi nama dengan tulisan ananda sendiri.</label>
      <div class="custom-file">
        <FileLister v-if="files.raporttk.size!=0" :filedata="files.raporttk.list" :num-visible="files.raporttk.size"></FileLister>
        <ProgressBar v-if="isupload3" class="mt-2 mb-2" :value="uploadprogress3" style="height: 10px;font-size: 14px"></ProgressBar>
        <FileUpload v-if="showupload" :class="classupload3" :fileLimit="10" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel3" @before-send="beforeUploadTiga" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="5000000" @progress="onProgressTiga" @upload="onUploadTiga" @select="onSelectTiga">
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
        <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg,.pdf | Maksimum 10 berkas | Minimum 1 berkas</keterangan-form>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <normal-label>Tuliskan data TK tambahan bila ada</normal-label>
      <input type="text" v-model="dataFormulir.tambahan" class="w-100 form-control"  >
    </div>
  </div>
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <p style="font-style:italic; color:red;">Bila Anda telah menyelesaikan formulir dan tombol "kirim" belum aktif, harap periksa kembali  formulir sebelumnya dan pastikan berkas-berkas dan data yang diperlukan telah dilengkapi.</p>
    </div>
  </div>

</template>

<script>
import "primevue/resources/themes/lara-light-indigo/theme.css";
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import useValidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import DashboardServices from "@/services/dashboard.services";
import FileLister from "@/components/FileLister";
export default {
  name: "PageFormDokumenPendukung",
  props:{
    data:[],
    idanak:String,
    initdata: undefined,
    showupload: Boolean
  },
  components:{FileLister, FileUpload,ProgressBar},
  emits: ['change-data'],
  data:()=>({
    v$: useValidate(),
    urlupload:'',
    classupload1:'',
    classupload2:'',
    classupload3:'',
    isupload1: false,
    isupload2: false,
    isupload3: false,
    uploadprogress1:0,
    uploadprogress2:0,
    uploadprogress3:0,
    uploadLabel1: 'Pilih',
    uploadLabel2: 'Pilih',
    uploadLabel3: 'Pilih',
    files:{
      aktekelahiran:{
        size:0,
        list:[]
      },
      kartukeluarga:{
        size:0,
        list:[]
      },
      raporttk:{
        size:0,
        list:[]
      },
    },
    dataFormulir:{
      tambahan:'',
      dokumen:{
        kelahiran:'',
        kartukeluarga:'',
        report:''
      }
    }
  }),
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
    beforeUploadSatu(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','aktekelahiran');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgressSatu(event){
      this.isupload1 = true;
      this.uploadprogress1 = event.progress;
    },
    onUploadSatu(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.dokumen.kelahiran = obj.data.fileid;
        this.classupload1='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload1='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelectSatu(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
      this.dataFormulir.dokumen.kelahiran = '';
    },
    beforeUploadDua(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','kartukeluarga');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgressDua(event){
      this.isupload2 = true;
      this.uploadprogress2 = event.progress;
    },
    onUploadDua(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.dokumen.kartukeluarga = obj.data.fileid;
        this.classupload2='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload2='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload2 = false;
    },
    onSelectDua(event){
      if(event.files.length >0){
        //this.uploadLabel2 = event.files[0].name;
      }
      this.dataFormulir.dokumen.kartukeluarga = '';
    },
    beforeUploadTiga(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','raporttk');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgressTiga(event){
      this.isupload3 = true;
      this.uploadprogress3 = event.progress;
    },
    onUploadTiga(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.dokumen.report = obj.data.fileid;
        this.classupload3='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload3='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload3 = false;
    },
    onSelectTiga(event){
      if(event.files.length >0){
        //this.uploadLabel3 = event.files[0].name;
      }
      this.dataFormulir.dokumen.report = '';
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.idanak};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              if (typeof this.response.data.aktekelahiran !== 'undefined') {
                this.files.aktekelahiran.list = this.response.data.aktekelahiran.list;
                this.files.aktekelahiran.size = this.response.data.aktekelahiran.size;
                if(typeof this.files.aktekelahiran.size != 'undefined'){
                  if(this.files.aktekelahiran.size>0){
                    if(typeof this.dataFormulir.dokumen !== 'undefined'){
                      if(typeof this.dataFormulir.dokumen.kelahiran !== 'undefined'){
                        if (typeof this.dataFormulir.dokumen.kelahiran !== "undefined") {
                          this.dataFormulir.dokumen.kelahiran = 'uploaded'
                        }
                      }
                    }
                  }
                }
              }
              if (typeof this.response.data.kartukeluarga !== 'undefined') {
                this.files.kartukeluarga.list = this.response.data.kartukeluarga.list;
                this.files.kartukeluarga.size = this.response.data.aktekelahiran.size;
                if(this.files.kartukeluarga.size>0){
                  if(typeof this.dataFormulir.dokumen !== 'undefined'){
                    if (typeof this.dataFormulir.dokumen.kartukeluarga !== "undefined") {
                      this.dataFormulir.dokumen.kartukeluarga = 'uploaded'
                    }
                  }
                }
              }
              if (typeof this.response.data.raporttk !== 'undefined') {
                this.files.raporttk.list = this.response.data.raporttk.list;
                this.files.raporttk.size = this.response.data.raporttk.size;
                if(this.files.raporttk.size>0){
                  if(typeof this.dataFormulir.dokumen !== 'undefined'){
                    if (typeof this.dataFormulir.dokumen.report !== "undefined") {
                      this.dataFormulir.dokumen.report = 'uploaded'
                    }
                  }
                }
              }
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    //this.$emit('change-data',this.dataFormulir);
    this.validate();
    this.getDataUploadedFile();
  },
  validations() {
    return {
      dataFormulir:{
        dokumen:{
          kelahiran:{required},
          kartukeluarga:{required},
          report:{required}
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
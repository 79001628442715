<template>
  <BreadCrumb v-if="showgrid" page="Beranda > Pendaftaran > Data Penerimaan > Lihat Data Diterima "></BreadCrumb>
  <div class="container content-area" v-if="showgrid">
    <div class="row">
      <div class="col-md-12">
        <h3 class="judul-seksi-sga">Data Diterima - SK dan Status Pembayaran Uang Sekolah Tahun Ajaran {{this.$store.getters.getTahunAJaran}}</h3>
      </div>
    </div>
    <div class="" style="padding-top: 20px;">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <DataTable v-model:filters="filters" :value="dataKeputusanDiterima" ref="dt" tableStyle="min-width: 50rem"
                       paginator :rows="10"
                       :rowsPerPageOptions="[5, 10, 20, 50]"
                       :first="0"
                       :loading="loading"
            >
              <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
              </template>
              <template #empty> Data tidak ada. </template>
              <template #loading> Mengambil data. Mohon tunggu. </template>
              <Column field="no_formulir" header="No formulir" sortable></Column>
              <Column field="nama_anak" header="Nama Anak" filterMatchMode="startsWith" sortable>
              </Column>
              <Column field="nama_ortu" header="Nama Ortu" sortable style="width: 25%"></Column>
              <Column field="jadwal_wawancara" header="Jadwal Wawancara" sortable></Column>
              <Column field="tanggal_wawancara" header="Tanggal Wawancara" sortable style="width: 25%"></Column>
              <Column>
                <template #body="slotProps">
                  <a href="#" @click="toggleGrid(slotProps.data)" class="fa-solid fa-eye"></a>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import {FilterMatchMode} from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
export default {
  name: "ViewDataPSBditerima",
  components: {BreadCrumb,DataTable,Column,InputText},
  data:()=>({
    loading: false,
    lazyParams: {},
    showgrid: true,
    response:'',
    message:'',
    success: false,
    dataKeputusanDiterima:[],
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'no_formulir': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'nama_anak': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'nama_ortu': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jadwal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'tanggal_wawancara': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
  })
}
</script>

<style scoped>

</style>
<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Pendaftaran > Data Berasa"></BreadCrumb>
  <div class="container content-area" v-if="!showformulir">
    <div class="row">
      <div class="col-md-12">
        <h3 class="judul-seksi-sga">Data Formulir Berasa {{this.$store.getters.getTahunAJaran}}</h3></div>
    </div>
    <div class="" style="padding-top: 20px;">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <DataTable v-model:filters="filters"
                       :value="dataBerasa"
                       ref="dt"
                       tableStyle="min-width: 50rem"
                       :paginator="true"
                       :rows="25"
                       :rowsPerPageOptions="[5, 10, 20,25, 50,100]"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate="Tampil {first} dari {last} of {totalRecords} data pengembalian"
                       :first="0"
                       :loading="loading"
                       class="p-datatable-sm"
                       sortMode="single"
                       stripedRows
            >
              <template #header>
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
              </template>
              <template #empty> Data tidak ada. </template>
              <template #loading> Mengambil data. Mohon tunggu. </template>
              <Column field="noformulir" header="No formulir" sortable style="min-width:16rem"></Column>
              <Column field="namaanak" header="Nama Anak" sortable style="min-width:16rem"></Column>
              <Column header="Berasa Ayah kandung" sortable style="min-width:16rem">
                <template #body="slotProps">
                    [ {{slotProps.data.ayahkandung.data.nama_ortu}} ]<br/>
                  <a href="#" @click="viewdataberasa(slotProps.data.ayahkandung)" v-if="slotProps.data.ayahkandung_status==='Lengkap'">
                    {{slotProps.data.ayahkandung_status}}
                  </a>
                  <p v-else>{{slotProps.data.ayahkandung_status}}</p>
                </template>
              </Column>
              <Column header="Berasa Ibu kandung" sortable style="min-width:16rem">
                <template #body="slotProps">
                  [ {{slotProps.data.ibukandung.data.nama_ortu}} ]<br/>
                  <a href="#" @click="viewdataberasa(slotProps.data.ibukandung)" v-if="slotProps.data.ibukandung_status==='Lengkap'">
                    {{slotProps.data.ibukandung_status}}
                  </a>
                  <p v-else>{{slotProps.data.ibukandung_status}}</p>
                </template>
              </Column>
              <Column header="Berasa Ayah tiri" sortable style="min-width:16rem">
                <template #body="slotProps">
                  [ {{slotProps.data.ayahtiri.data.nama_ortu}} ]<br/>
                  <a href="#" @click="viewdataberasa(slotProps.data.ayahtiri)" v-if="slotProps.data.ayahtiri_status==='Lengkap'">
                    {{slotProps.data.ayahtiri_status}}
                  </a>
                  <p v-else>{{slotProps.data.ayahtiri_status}}</p>
                </template>
              </Column>
              <Column header="Berasa Ibu tiri" sortable style="min-width:16rem">
                <template #body="slotProps">
                  [ {{slotProps.data.ibutiri.data.nama_ortu}} ]<br/>
                  <a href="#" @click="viewdataberasa(slotProps.data.ibutiri)" v-if="slotProps.data.ibutiri_status==='Lengkap'">
                    {{slotProps.data.ibutiri_status}}
                  </a>
                  <p v-else>{{slotProps.data.ibutiri_status}}</p>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PageViewIsiBerasa :data="currentDataBerasa" v-if="showformulir" @change-view="toogleviewshowgrid"></PageViewIsiBerasa>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import {FilterMatchMode} from "primevue/api";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from "primevue/inputtext";
import PageViewIsiBerasa from "@/components/viewadmin/berasa/PageViewIsiBerasa";
export default {
  name: "ViewDataBerasa",
  components: {PageViewIsiBerasa, BreadCrumb,DataTable,Column,InputText},
  data:()=>({
    dataBerasa: [],
    loading: false,
    message: '',
    success: false,
    dataInformasi:[],
    response:'',
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'tgl': {value: '', matchMode: FilterMatchMode.CONTAINS},
      'noinv': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'jml': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'hargaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
      'biayaformulir': {value: '', matchMode: FilterMatchMode.STARTS_WITH},
    },
    showformulir: false,
    currentDataBerasa:[],
  }),
  methods:{
    toogleviewshowgrid(){
      this.showformulir = false;
    },
    viewdataberasa(data){
      this.currentDataBerasa = data;
      this.showformulir = true;
    },
    getDataBerasa(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = this.accountData;
      let path = '/dashboard/getDataBerasa';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataBerasa = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataBerasa();
  }
}
</script>

<style scoped>

</style>
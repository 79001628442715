<template>
  <!--Catatan Pribadi Anak-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Karakteristik Umum
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label>Berikan gambaran tentang karakteristik ananda. Beri tanda centang pada kata-kata yang dianggap tepat untuk menggambarkan ananda, kemudian berikan deskripsi singkat. Minimal 5 karakteristik.</label>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="form-check" >
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="penggembira">
        <normal-label2 class="form-check-label" for="defaultCheck1" >
          penggembira
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="pendiam">
        <normal-label2 class="form-check-label" for="defaultCheck2">
          pendiam
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="mudah menangis"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          mudah menangis
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="mudah berteman"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          mudah berteman
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="ingin selalu berhasil"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          ingin selalu berhasil
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="penurut"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          penurut
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="gigih"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          gigih
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="selalu ingin ada orang lain di sekitarnya"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          selalu ingin ada orang lain di sekitarnya
        </normal-label2>
      </div>
    </div>
    <!--split-->
    <div class="col-md-4">
      <div class="form-check" >
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="pemalu"  >
        <normal-label2 class="form-check-label" for="defaultCheck1" >
          pemalu
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="perasa/sensitif"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          perasa/sensitif
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="selalu ingin tahun"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          selalu ingin tahu
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="dapat bermain sendiri"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          dapat bermain sendiri
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="suka membuat orang tertawa"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          suka membuat orang tertawa
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="cuek"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          cuek
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="mudah bosan"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          mudah bosan
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="menempel pada orang tua bila sedang bepergian"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          "menempel" pada orang tua bila sedang bepergian
        </normal-label2>
      </div>
    </div>
    <!--split-->
    <div class="col-md-4">
      <div class="form-check" >
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="pemberani"  >
        <normal-label2 class="form-check-label" for="defaultCheck1" >
          pemberani
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="mudah marah"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          mudah marah
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="mudah merajuk"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          mudah merajuk
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="kurang suka tidur"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          kurang suka diatur
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="sangat senang diperhatikan"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          sangat senang diperhatikan
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="serius"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          serius
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="menyukai kebebasan"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          menyukai kebebasan
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.karakter" type="checkbox" value="tidak bisa duduk diam"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          tidak bisa duduk diam
        </normal-label2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <label>Ceritakan karakteristik ananda secara deskriptif. Berikan contoh-contoh untuk memberikan gambaran yang jelas.</label>
      <textarea v-model="dataFormulir.deskripsi" class="w-100 form-control textarea-sga" rows="5" required></textarea>
    </div>
  </div>
  <!--hubungan dengan keluarga-->

  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Hubungan Dalam Keluarga
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12 spacer-field-sga">
      <label>Ceritakan hubungan antar anggota keluarga, dengan siapa ananda paling dekat, kebiasaan keluarga, seberapa sering keluarga melakukan kegiatan bersama, peranan orang tua dalam proses belajar anak-anaknya, dll.</label>
      <textarea v-model="dataFormulir.hubunganantarkeluarga" class="w-100 form-control textarea-sga" rows="5" required></textarea>
    </div>
  </div>

  <!--rutinitas sehari hari-->

  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Rutinitas Sehari-hari
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <rutinitas>
        <div class="row">
          <div class="col-12  spacer-field-sga "><label>Bangun pagi</label></div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.bangunpagi" type="radio"  value="bangun sendiri" name="bangunpagi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">bangun sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.bangunpagi" type="radio"  value="mudah dibangunkan" name="bangunpagi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">mudah dibangunkan</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.bangunpagi" type="radio"  value="sukar dibangunkan" name="bangunpagi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">sukar dibangunkan</normal-label2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <label>Jam bangun pagi</label>
            <input type="time" v-model="dataFormulir.jambangunpagi" class="w-100 form-control"  required>
            <keterangan-form>Format penulisan jam:menit. Contoh 7.30</keterangan-form>
          </div>
        </div>
      </rutinitas>
      <rutinitas>
        <div class="row">
          <div class="col-12  spacer-field-sga "><label>Mandi pagi</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.mandipagi" type="radio"  value="mandi sendiri" name="mandi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">mandi sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.mandipagi" type="radio"  value="diawasi dibantu" name="mandi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">diawasi/dibantu</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.mandipagi" type="radio"  value="dimandikan" name="mandi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">dimandikan</normal-label2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <label>Jam mandi pagi</label>
            <input type="time" v-model="dataFormulir.jammandipagi" class="w-100 form-control"  required>
            <keterangan-form>Format penulisan jam:menit. Contoh 7.30</keterangan-form>
          </div></div>
      </rutinitas>
      <rutinitas>
        <div class="row">
          <div class="col-12 w-100  spacer-field-sga "><label>Makan pagi</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.makanpagi" type="radio"  value="makan sendiri" name="makanpagi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">makan sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.makanpagi" type="radio"  value="disuapi" name="makanpagi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">disuapi</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.makanpagi" type="radio"  value="makan di mobil" name="makanpagi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">makan di mobil</normal-label2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 spacer-field-sga  w-100">
            <label>Jam makan pagi</label>
            <input type="time" v-model="dataFormulir.jammakanpagi" class="w-100 form-control"  required>
            <keterangan-form>Format penulisan jam:menit. Contoh 7.30</keterangan-form>
          </div>
        </div>
      </rutinitas>
      <rutinitas>
        <div class="row">
          <div class="col-12 w-100  spacer-field-sga "><label>Makan siang</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.makansiang" type="radio"  value="makan sendiri" name="makansiang" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">makan sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.makansiang" type="radio"  value="disuapi" name="makansiang" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">disuapi</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.makansiang" type="radio"  value="makan di mobil" name="makansiang" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">makan di mobil</normal-label2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 spacer-field-sga  w-100">
            <label>Jam makan siang</label>
            <input type="time" v-model="dataFormulir.jammakansiang" class="w-100 form-control"  required>
            <keterangan-form>Format penulisan jam:menit. Contoh 7.30</keterangan-form>
          </div>
        </div>
      </rutinitas>
    </div>

    <div class="col-md-6">
      <rutinitas>
        <div class="row">
          <div class="col-12  spacer-field-sga "><label>Tidur siang</label></div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.tidursiang" type="radio"  value="setiap hari" name="tidursiang" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">setiap hari</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.tidursiang" type="radio"  value="kadang kadang" name="tidursiang" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">kadang kadang</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.tidursiang" type="radio"  value="tidak pernah" name="tidursiang" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">tidak pernah</normal-label2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <label>Jam tidur siang</label>
            <input type="time" v-model="dataFormulir.jamtidursiang" class="w-100 form-control"  required>
            <keterangan-form>Format penulisan jam:menit. Contoh 7.30</keterangan-form>
          </div>
        </div>
      </rutinitas>
      <rutinitas>
        <div class="row">
          <div class="col-12  spacer-field-sga "><label>Makan malam</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.makanmalam" type="radio"  value="makan sendiri" name="makanmalam" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">makan sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.makanmalam" type="radio"  value="disuapi" name="makanmalam" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">disuapi</normal-label2>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-12 spacer-field-sga">
            <label>Jam makan malam</label>
            <input type="time" v-model="dataFormulir.jammakanmalam" class="w-100 form-control"  required>
            <keterangan-form>Format penulisan jam:menit. Contoh 7.30</keterangan-form>
          </div></div>
      </rutinitas>
      <rutinitas>
        <div class="row">
          <div class="col-12 w-100  spacer-field-sga "><label>Tidur malam</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.tidurmalam" type="radio"  value="tidur sendiri" name="tidurmalam" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">tidur sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.tidurmalam" type="radio"  value="ditemani hingga tertidur" name="tidurmalam" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">ditemani hingga tertidur</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.tidurmalam" type="radio"  value="bersama orang tua" name="tidurmalam" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">bersama orang tua</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.tidurmalam" type="radio"  value="bersama pengasuh" name="tidurmalam" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">bersama pengasuh</normal-label2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 spacer-field-sga  w-100">
            <label>Jam tidur malam</label>
            <input type="time" v-model="dataFormulir.jamtidurmalam" class="w-100 form-control"  required>
            <keterangan-form>Format penulisan jam:menit. Contoh 7.30</keterangan-form>
          </div>
        </div>
      </rutinitas>
    </div>
  </div>
  <!--keterampilan (skills)-->

  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Keterampilan
    </h3><hr>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <ketrampilan>
        <div class="row">
          <div class="col-12 w-100  spacer-field-sga "><label>Menggosok gigi</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.gosokgigi" type="radio"  value="sendiri" name="gosokgigi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.gosokgigi" type="radio"  value="dibantu" name="gosokgigi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">dibantu</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.gosokgigi" type="radio"  value="digosokkan" name="gosokgigi" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">digosokkan</normal-label2>
            </div>
          </div>
        </div>

      </ketrampilan>

      <ketrampilan>
        <div class="row">
          <div class="col-12 w-100  spacer-field-sga "><label>Memilih pakaian</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.memilihpakaian" type="radio"  value="sendiri" name="pilihpakaian" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.memilihpakaian" type="radio"  value="dibantu" name="pilihpakaian" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">dibantu</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.memilihpakaian" type="radio"  value="dipilihkan" name="pilihpakaian" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">dipilihkan</normal-label2>
            </div>
          </div>
        </div>

      </ketrampilan>

    </div>
    <div class="col-md-6">
      <ketrampilan>
        <div class="row">
          <div class="col-12 w-100  spacer-field-sga "><label>Mengenakan pakaian</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.mengenakanpakaian" type="radio"  value="sendiri" name="pakaibaju" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.mengenakanpakaian" type="radio"  value="dibantu" name="pakaibaju" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">dibantu</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.mengenakanpakaian" type="radio"  value="dipakaikan" name="pakaibaju" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">dipakaikan</normal-label2>
            </div>
          </div>
        </div>

      </ketrampilan>
      <ketrampilan>
        <div class="row">
          <div class="col-12 w-100  spacer-field-sga "><label>Mengenakan sepatu</label></div></div>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.mengenakansepatu" type="radio"  value="sendiri" name="sepatu" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox1">sendiri</normal-label2>
            </div>
            <div class="form-check form-check-inline ">
              <input class="form-check-input" v-model="dataFormulir.mengenakansepatu" type="radio"  value="dibantu" name="sepatu" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox2">dibantu</normal-label2>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" v-model="dataFormulir.mengenakansepatu" type="radio"  value="dipakaikan" name="sepatu" required>
              <normal-label2 class="form-check-label" for="inlineCheckbox3">dipakaikan</normal-label2>
            </div>
          </div>
        </div>
      </ketrampilan>
    </div>
  </div>
  <!-- Kegiatan yang disukai -->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Kegiatan yang disukai
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label>Kegiatan yang disukai Ananda</label>
    </div>
  </div>

  <div class="row">

    <div class="col-md-4">
      <div class="form-check" >
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Membaca"  >
        <normal-label2 class="form-check-label" for="defaultCheck1" >
          Membaca
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Main Musik"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Main musik
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Main permainan komputer (daring/luring)"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Main permainan komputer (daring/luring)
        </normal-label2>
      </div>

    </div>
    <!--split-->
    <div class="col-md-4">
      <div class="form-check" >
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Menggambar"  >
        <normal-label2 class="form-check-label" for="defaultCheck1" >
          Menggambar
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Menonton TV"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Menonton TV
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Main di luar rumah"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Main di luar rumah
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Main sepeda"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Main sepeda
        </normal-label2>
      </div>

    </div>
    <!--split-->
    <div class="col-md-4">
      <div class="form-check" >
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Mendengarkan musik"  >
        <normal-label2 class="form-check-label" for="defaultCheck1" >
          Mendengarkan musik
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Menonton kanal Youtube"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Menonton kanal Youtube
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Les tambahan"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Les tambahan
        </normal-label2>
      </div>
      <div class="form-check">
        <input class="form-check-input" v-model="dataFormulir.kegiatandisukai" type="checkbox" value="Main bola"  >
        <normal-label2 class="form-check-label" for="defaultCheck2">
          Main bola
        </normal-label2>
      </div>
    </div>
  </div>

</template>

<script>
import useValidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name: "PageFormKarakteristikUmum",
  props:{
    data:[],
    initdata: undefined
  },
  emits: ['change-data'],
  data:()=>({
    v$: useValidate(),
    loading: false,
    message:'',
    success:false,
    urlupload:'',
    dataFormulir:{
      karakter:[],
      deskripsi:'',
      hubunganantarkeluarga:'',
      bangunpagi:'',
      jambangunpagi:'',
      mandipagi:'',
      jammandipagi:'',
      makanpagi:'',
      jammakanpagi:'',
      makansiang:'',
      jammakansiang:'',
      makanmalam:'',
      jammakanmalam:'',
      tidurmalam:'',
      jamtidurmalam:'',
      tidursiang:'',
      jamtidursiang:'',
      gosokgigi:'',
      memilihpakaian:'',
      mengenakanpakaian:'',
      mengenakansepatu:'',
      kegiatandisukai:[],
      photo:''
    }
  }),
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
  },
  mounted() {
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.dataFormulir);
    this.validate();
  },
  validations() {
    return {
      dataFormulir:{
        photo:{required}
      }
    }
  }
}
</script>

<style scoped>

</style>
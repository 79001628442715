<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Wawancara > Data Jadwal Wawancara > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Hasil Wawancara - {{data.nama_anak}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="form-general">
        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="col-12 spacer-field-sga">
                <normal-label>No formulir</normal-label>
                <br>
                <normal-label2>{{data.no_formulir}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Ananda</normal-label>
                <br>
                <normal-label2>{{data.nama_anak}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Orang Tua</normal-label>
                <ul>
                  <li>
                    <normal-label2>{{data.nama_ayah}}</normal-label2>
                  </li>
                  <li>
                    <normal-label2>{{data.nama_ibu}}</normal-label2>
                  </li>
                  <li v-if="data.nama_ayah_tiri">{{data.nama_ayah_tiri}}</li>
                  <li v-if="data.nama_ibu_tiri">{{data.nama_ibu_tiri}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a :class="getClass(1)" @click="setpage(1)" data-toggle="tab" href="#home">Catatan Wawancara</a>
              </li>
              <li class="nav-item">
                <a :class="getClass(2)" @click="setpage(2)" data-toggle="tab" href="#menu1">Data yang tersedia</a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content" style="margin-top: 30px;">
              <div :class="getTabClass(1)" id="home">
                <div class="row">
                  <div class="col-12 spacer-field-sga  w-100"><label>Catatan hasil wawancara</label>
                  </div>
                  <div class="col-12  spacer-field-sg">
                                        <textarea class="form-control textarea-sga" v-model="catatan"
                                                  rows="4" required=""></textarea>
                  </div>
                  <div class="col-12 button-area-sga text-right">
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-12">
                    <label>Dokumen wawancara : </label>
                    <div class="custom-file">
                      <FileLister v-if="!loading" :filedata="filelist.list" :num-visible="filelist.size"></FileLister>
                      <FileUpload v-if="showupload" :class="classupload1" :fileLimit="10" :showUploadButton="false" :showCancelButton="false" :multiple="true" :chooseLabel="uploadLabel1" @before-send="beforeUploadSatu" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="5500000" @progress="onProgressSatu" @upload="onUploadSatu" @select="onSelectSatu"></FileUpload>
                      <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg atau
                        .pdf
                      </keterangan-form>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <normal-label>Status Rekomendasi : <select name="status-pelaksanaan-penilaian"
                                                               class="sga-status-dropdown" v-model="statuspelaksanaan">

                      <option value="Belum ada rekomendasi">belum ada rekomendasi</option>
                      <option value="Rekomendasi tidak diterima">Rekomendasi tidak diterima</option>
                      <option value="Rekomendasi diterima">Rekomendasi diterima</option>
                    </select></normal-label>
                    <button @click="updateHasilWawancaraOrtu" class="btn btn-sm btn-success">Simpan</button>
                  </div>
                </div>
              </div>
              <div :class="getTabClass(2)" id="menu1">
                <div class="row">
                  <div class="col-12"><h5 style="color: darkorange;">Formulir pendaftaran</h5>
                    <p>
                      <router-link :to=generatelink(data.invid) target= '_blank'>
                        Lihat formulir
                      </router-link>
                    </p>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data wawancara awal
                    (Psikologi)</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{datacatatan.psikolog}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPsikolog.list" :num-visible="filelistPsikolog.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data test kognitif </h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{datacatatan.kognitif}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistKognitif.list" :num-visible="filelistKognitif.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data kelas coba belajar</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan PIC PSB</strong> :</p>
                        <p>{{datacatatan.cobabelajar}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPercobaan.list" :num-visible="filelistPercobaan.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data formulir Berasa</h5>
                    <div class="row">
                      <div class="col-12">
                        <ul>
                          <li v-for="(berasa,idx) in berasa" :key="idx">
                            <span class="text-primary" style="cursor: pointer" @click="viewberasa(berasa)" target="_blank">{{berasa.tipe_pengisi}}</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data Catatan Diskusi</h5>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Akademik</strong>:</p>
                        <p>{{datacatatan.catatandiskusi.scoring_akademik}}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Akademik Anak</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_akademik}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Sikap</strong>:</p>
                        <p>{{datacatatan.catatandiskusi.scoring_sikap}}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Sikap Anak</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_sikap}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12"><p><strong>Yang masih perlu dikembangkan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.masih_perlu_kembangkan}}</p>
                      </div>
                      <div class="col-12"><p><strong>Apa yang perlu ditanyakan lebih lanjut kepada
                        orang tua</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.tanya_ortu}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kualitas informasi</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kualitas_informasi}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kualitas_informasi_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Temperamen</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.temperamen}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.temperamen_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Keterlibatan orang tua</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.keterlibatan_ortu}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.keterlibatan_ortu_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kedekatan dalam keluarga</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kedekatan_keluarga}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kedekatan_keluarga_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Aktivitas</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.aktivitas}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kemandirian</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kemandirian}}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kemandirian_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Limitasi kesehatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.limitasi}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kebutuhan khusus/kesubel</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kebutuhan_khsusu}}</p>
                      </div>
                      <div class="col-4"><p><strong>Tambahan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.tambahan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Raport TK</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.raport_tk}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kehadiran</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kehadiran}}</p>
                        <p>{{datacatatan.catatandiskusi.kehadiran_catatan}}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_akhir}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="visible" modal :header="berasaheader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <ViewHasilBerasa :databerasa="currentberasa"></ViewHasilBerasa>
  </Dialog>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import FileLister from "@/components/FileLister";
import FileUpload from "primevue/fileupload";
import DashboardServices from "@/services/dashboard.services";
import Dialog from 'primevue/dialog';
import ViewHasilBerasa from "@/components/viewadmin/wawancara/ViewHasilBerasa";
export default {
  name: "PageUpdateDataWawancara",
  props:{
    data: undefined
  },
  emits: ['change-view'],
  components: {ViewHasilBerasa, BreadCrumb,FileUpload,FileLister,Dialog},
  data:()=>({
    loading: false,
    showupload: true,
    classupload1:'',
    urlupload:'',
    uploadLabel1:'',
    visible: false,
    filelist:[],
    filelistPercobaan:[],
    filelistKognitif:[],
    filelistPsikolog:[],
    page:1,
    statuspelaksanaan:'',
    currentberasa:[],
    catatan:'',
    berasa:[],
    berasaheader:"",
    datacatatan:{
      psikolog:'',
      cobabelajar:'',
      kognitif:'',
      catatandiskusi:[]
    },
    datakegiatan:[]
  }),
  methods:{
    viewberasa(item){
      this.berasaheader = item.tipe_pengisi;
      this.currentberasa = item.data.formulir;
      this.visible = true;
    },
    beforeUploadSatu(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','wawancaraortu');
      request.formData.append('invid',this.data.invcode);
      return request;
    },
    onProgressSatu(event){
      this.isupload1 = true;
      this.uploadprogress1 = event.progress;
    },
    onUploadSatu(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.classupload1='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload1='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload1 = false;
    },
    onSelectSatu(event){
      if(event.files.length >0){
        //this.uploadLabel1 = event.files[0].name;
      }
    },
    getClass(id){
      if(this.page===id){
        return 'nav-link active';
      }else{
        return 'nav-link';
      }
    },
    getTabClass(id){
      if(this.page===id){
        return 'tab-pane container active';
      }else{
        return 'tab-pane container fade';
      }
    },
    setpage(id){
      this.page = id;
    },
    toggleview(){
      this.$emit('change-view',true)
    },
    updateHasilWawancaraOrtu(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        'idjadwal': this.data.jadwal_id,
        'status': this.statuspelaksanaan,
        'catatan': this.catatan
      };
      let path = '/dashboard/updateHasilKegiatan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    generatelink(invchild){
      return "/admin/data/view-formulir-psb/"+invchild;
    },
    getDataKegiatan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        invid:this.data.idanak,
        idformulir:this.data.idformulir
      };
      let path = '/dashboard/getDataHasilKegiatanAnak';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.datakegiatan = this.response.data;
              this.berasa = this.datakegiatan.berasa;
              if (typeof this.response.data.psikolog.catatan_kegiatan !== 'undefined') {
                this.datacatatan.psikolog = this.response.data.psikolog.catatan_kegiatan;
              }
              if (typeof this.response.data.kognitif.catatan_kegiatan !== 'undefined') {
                this.datacatatan.kognitif = this.response.data.kognitif.catatan_kegiatan;
              }
              if (typeof this.response.data.cobabelajar.catatan_kegiatan !== 'undefined') {
                this.datacatatan.cobabelajar = this.response.data.cobabelajar.catatan_kegiatan;
              }
              if (typeof this.response.data.catatandiskusi !== 'undefined') {
                this.datacatatan.catatandiskusi = this.response.data.catatandiskusi;
              }
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {
        paramname:this.data.invcode,
        //filecat:'wawancaraortu'
      };
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              if (typeof this.response.data.wawancaraortu !== 'undefined') {
                this.filelist = this.response.data.wawancaraortu;
              }
              if (typeof this.response.data.kelaspercobaan !== 'undefined') {
                this.filelistPercobaan = this.response.data.kelaspercobaan;
              }
              if (typeof this.response.data.testkognitif !== 'undefined') {
                this.filelistKognitif = this.response.data.testkognitif;
              }
              if (typeof this.response.data.wpsikolog !== 'undefined') {
                this.filelistPsikolog = this.response.data.wpsikolog;
              }
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.catatan = this.data.catatan_kegiatan;
    this.statuspelaksanaan = this.data.rekomendasi;
    this.getDataUploadedFile();
    this.getDataKegiatan();
  }
}
</script>

<style scoped>

</style>
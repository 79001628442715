<template>
  <!--DATA PRIBADI-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>F. Kebutuhan/Kondisi Khusus
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <label>Apakah ada kondisi fisik, medis, atau intelektual yang perlu diketahui oleh sekolah?</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.kondisikhusus" name="inlineRadioOptions" id="inlineRadio1" value="ya" >
            <span class="normal-label2 form-check-label" for="inlineRadio1">Ya</span>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataFormulir.kondisikhusus" name="inlineRadioOptions" id="inlineRadio2" value="tidak" >
            <span class="normal-label2 form-check-label" for="inlineRadio2">Tidak</span>
          </div>
        </div>
        <div class="col-12 spacer-field-sga col-keterangan-form">
          <span class="normal-label">Bila ya, kondisi tersebut adalah</span>
          <textarea v-model="dataFormulir.namakondisi" class="w-100 form-control textarea-sga" rows="6" ></textarea>
          <span class="keterangan-form" :class="v$.dataFormulir.namakondisi.$error?'text-danger':''">Berikan informasi lebih rinci dalam form kesehatan. Bila berkaitan dengan kebutuhan khusus (<i>special needs</i>) atau kesulitan belajar, lampirkan surat keterangan dari dokter/ahli terapis yang menangani. </span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <normal-label>Dokter atau ahli yang dapat memberikan keterangan</normal-label>
          <input type="text" v-model="dataFormulir.dokter.nama" class="w-100 form-control"  >
          <keterangan-form>Nama lengkap Dokter </keterangan-form>
        </div>
        <div class="col-12 spacer-field-sga">
          <normal-label>Alamat</normal-label>
          <textarea type="text" v-model="dataFormulir.dokter.alamat" class="w-100 form-control textarea-sga"  ></textarea>
          <keterangan-form>Alamat lengkap Dokter</keterangan-form>
        </div>
        <div class="col-12 spacer-field-sga">
          <normal-label>Telepon Dokter</normal-label>
          <input type="text" v-model="dataFormulir.dokter.telp" class="w-100 form-control"  >
          <keterangan-form>No telepon Dokter</keterangan-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import {required,requiredIf} from "@vuelidate/validators";

export default {
  name: "PageFormKondisiKhusus",
  props:{
    data:[],
    initdata: undefined
  },
  emits: ['change-data'],
  data:()=>({
    v$: useValidate(),
    loading: false,
    message:'',
    success:false,
    dataFormulir:{
      kondisikhusus:'',
      namakondisi:'',
      dokter:{
        nama:'',
        alamat:'',
        telp:''
      }
    }
  }),
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
  },
  mounted() {
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    this.validate();
  },
  validations() {
    return {
      dataFormulir:{
        kondisikhusus: {required },
        namakondisi: {required:requiredIf(this.dataFormulir.kondisikhusus==='ya')}
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <!--Pernyataan Orang Tua   -->
  <surat-pernyataan-orangtua>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <normal-label2>Kami yang mendaftarkan ananda ke Sekolah Gemala Ananda:</normal-label2>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-4 spacer-field-sga">
                <label :class="v$.dataFormulir.pernyataan.ayah.$error?'text-danger':''">Nama (Ayah/Wali)</label>
                <input type="text" v-model="dataFormulir.pernyataan.ayah" class="w-100 form-control"  required>
              </div>
              <div class="col-md-4 spacer-field-sga">
                <label :class="v$.dataFormulir.pernyataan.ibu.$error?'text-danger':''">Nama (Ibu/Wali)</label>
                <input type="text" v-model="dataFormulir.pernyataan.ibu" class="w-100 form-control"  required>
              </div>
              <div class="col-md-4 spacer-field-sga">
                <label :class="v$.dataFormulir.pernyataan.namasiswa.$error?'text-danger':''">Selaku Orang tua/wali dari:</label>
                <input disabled="disabled" type="text" v-model="dataFormulir.pernyataan.namasiswa" class="w-100 form-control"  required>
                <keterangan-form></keterangan-form>
              </div>
            </div></div>
        </div></div>
      <div class="col-12">
        <label :class="v$.dataFormulir.menyatakan.$error?'text-danger':''">Menyatakan bahwa kami:</label>
        <div class="form-check form-check-inline spacing-checkbox-sga">
          <input class="form-check-input" v-model="dataFormulir.menyatakan.bersedia" type="checkbox"  value="Facebook"  required>
          <normal-label2 class="form-check-label" for="inlineCheckbox1">Bersedia untuk menjalankan kemitraan dengan Sekolah Dasar Gemala Ananda (selanjutnya disebut Sekolah) berdasarkan nilai-nilai keterbukaan, saling menghargai, dan sinergi, dalam mengupayakan kesempatan optimal bagi Anak Kami untuk mengenali diri dan mengasah potensinya;	</normal-label2>
        </div>
        <div class="form-check form-check-inline spacing-checkbox-sga">
          <input class="form-check-input" v-model="dataFormulir.menyatakan.memahami" type="checkbox"  value="Instagram" required>
          <normal-label2 class="form-check-label" for="inlineCheckbox2">Telah memahami dan bersedia untuk mematuhi seluruh peraturan Sekolah yang berlaku, termasuk tetapi tidak terbatas Umum untuk melaksanakan dan menyelesaikan kewajiban Kami sebagaimana dijelaskan dalam Ketentuan Sekolah Dasar Gemala Ananda dan aturan lain yang ditetapkan oleh Sekolah termasuk seluruh perubahannya;				</normal-label2>
        </div>
        <div class="form-check form-check-inline spacing-checkbox-sga">
          <input class="form-check-input" v-model="dataFormulir.menyatakan.bersediaaktif" type="checkbox"  value="Website"   required>
          <normal-label2 class="form-check-label" for="inlineCheckbox3">Bersedia untuk terlibat secara aktif dalam proses pembelajaran Anak Kami, baik di rumah maupun dalam aktivitas di Sekolah, karena kami menyadari bahwa keberhasilan belajar Anak Kami tidak saja ditentukan oleh proses belajar di sekolah tetapi juga oleh keterlibatan kami selaku orang tua. Ketidakterlibatan Kami dapat berakibat pada konsekuensi terberat yaitu pengakhiran masa belajar Anak Kami di Sekolah sebagaimana dijelaskan dalam Ketentuan Umum Sekolah Dasar Gemala Ananda Bab II Butir (3);		</normal-label2>
        </div>
        <div class="form-check form-check-inline spacing-checkbox-sga">
          <input class="form-check-input" v-model="dataFormulir.menyatakan.memberiizin" type="checkbox"  value="Website"   required>
          <normal-label2 class="form-check-label" for="inlineCheckbox3">Memberikan izin tanpa batasan apa pun kepada Sekolah untuk menggunakan hasil dan dokumentasi kegiatan dan pembelajaran Anak Kami, termasuk tetapi tidak terbatas kepada hasil karya, foto, video, dan dokumentasi lainnya untuk keperluan publikasi Sekolah, internal maupun eksternal;	</normal-label2>
        </div>
        <div class="form-check form-check-inline spacing-checkbox-sga">
          <input class="form-check-input" v-model="dataFormulir.menyatakan.memberitahukan" type="checkbox"  value="Website"   required>
          <normal-label2 class="form-check-label" for="inlineCheckbox3">Akan memberitahukan pada kesempatan pertama setiap terjadinya perubahan status hukum Kami yang akan mempengaruhi pelaksanaan dari Surat Pernyataan ini dan/atau Anak Kami di Sekolah secara langsung maupun tidak langsung, dan Kami akan bekerja sama dengan Sekolah untuk memastikan tetap terpenuhinya komitmen Kami bagi pendidikan Anak Kami.		</normal-label2>
        </div>
      </div>
    </div>
  </surat-pernyataan-orangtua>
  <!--Penyerahan dokumen "Surat Pernyataan Orang Tua"-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Penyerahan dokumen "Surat Pernyataan Orang Tua"
    </h3><hr>
    </div>
  </div>

  <surat-pernyataan-orangtua>
    <p>Harap unduh surat pernyataan dari tautan berikut ini ( <a href="http://formulir.gemalaananda.sch.id/wp-content/uploads/2020/10/SGA_5_SuratPernyataan-2021-2022.pdf" target="_blank"><i class="fa-solid fa-download"></i> Berkas surat pernyataan</a> ), <strong>kemudian cetak dan unggah berkas yang sudah ditandatangani basah</strong>.</p>
    <p>Harap <strong>kirimkan berkas surat pernyataan asli</strong> yang telah ditandatangani basah ke alamat berikut : <br><br>
      <em>SD Gemala Ananda
        Bagian Administrasi<br>
        Jl. Lebak Bulus III/93, Gn. Balong<br>
        Cilandak, Jakarta 12440</em></p>

    <div class="row">
      <div class="col-12 spacer-field-sga">
        <label :class="v$.dataFormulir.dokumen.$error?'text-danger':''">Unggah dokumen Surat Pernyataan Orang Tua</label>
        <div class="custom-file">
          <FileLister v-if="files.suratpernyataan.size!=0" :filedata="files.suratpernyataan.list" :num-visible="files.suratpernyataan.size"></FileLister>
          <ProgressBar v-if="isupload" class="mt-2 mb-2" :value="uploadprogress" style="height: 10px;font-size: 14px"></ProgressBar>
          <FileUpload v-if="showupload" :class="classupload" :showUploadButton="false" :showCancelButton="false" :multiple="false" :chooseLabel="uploadLabel" @before-send="beforeUpload" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="1200000" @progress="onProgress" @upload="onUpload" @select="onSelect">
            <template #empty>
              <p>Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
          <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg, .pdf</keterangan-form>
        </div>
      </div>
    </div>
  </surat-pernyataan-orangtua>
</template>

<script>
import "primevue/resources/themes/lara-light-indigo/theme.css";
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import useValidate from "@vuelidate/core";
import {required,minLength} from "@vuelidate/validators";
import DashboardServices from "@/services/dashboard.services";
import FileLister from "@/components/FileLister";
export default {
  name: "PageSuratPernyataanOrangTua",
  props:{
    data:[],
    idanak:String,
    initdata: undefined,
    showupload: Boolean
  },
  components:{FileLister, FileUpload,ProgressBar},
  emits: ['change-data'],
  data:()=>({
    v$: useValidate(),
    uploadprogress:0,
    classupload:'',
    uploadLabel: 'Pilih',
    isupload: false,
    loading: false,
    message: '',
    success: false,
    urlupload: '',
    files:{
      suratpernyataan:{
        size:0,
        list:[]
      }
    },
    dataFormulir: {
      pernyataan:{
        ayah:'',
        ibu:'',
        namasiswa:'',
      },
      menyatakan:{required, minlength: minLength(5)},
      dokumen:'',
    }
  }),
  methods:{
    validate() {
      this.v$.dataFormulir.$validate();
    },
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','suratpernyataan');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgress(event){
      this.isupload = true;
      this.uploadprogress = event.progress;
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.dokumen = obj.data.fileid;
        this.classupload='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel = event.files[0].name;
      }
      this.dataFormulir.dokumen = '';
    },
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.idanak};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              if (typeof this.response.data.suratpernyataan != "undefined") {
                this.files.suratpernyataan.list = this.response.data.suratpernyataan.list;
                this.files.suratpernyataan.size = this.response.data.suratpernyataan.size;
                if(this.files.suratpernyataan.size>0){
                  this.dataFormulir.dokumen = 'uploaded';
                }
              }
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    //this.$emit('change-data',this.dataFormulir);
    this.getDataUploadedFile();
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    this.validate();
  },
  validations() {
    return {
      dataFormulir:{
        pernyataan:{
          ayah:{required},
          ibu:{required},
          namasiswa:{required},
        },
        menyatakan:{
          bersedia:{checked: value => value === true},
          memahami:{checked: value => value === true},
          bersediaaktif:{checked: value => value === true},
          memberiizin:{checked: value => value === true},
          memberitahukan:{checked: value => value === true},
        },
        dokumen:{required},
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <Bread-crumb page="Beranda > Pendaftaran > Pembayaran Uang Sekolah"></Bread-crumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Pembayaran Uang Sekolah</h3>
        <hr>
        <div class="form-general">
          <div class="row">
            <div class="col-12 text-left">
              <p>Lakukan pembayaran pelunasan uang sekolah untuk Ananda berikut ini, sebagai syarat kelengkapan Pendaftaran Siswa Baru (PSB) Sekolah Gemala Ananda</p><p>Anda akan dialihkan ke laman pembayaran uang sekolah melalui payment gateway E2Pay.</p>
              <div class="table-data-sga">
                <div class="table-responsive">
                  <TabelDataBiayaPelunasan :ispembayaran="true" :datapendaftaran="dataPendaftaran" buttontitle="Bayar tanda konfirmasi" routepath="/gemala/psb/bayar-biaya-konfirmasi/" :withbutton=false :withharga="true"></TabelDataBiayaPelunasan>
                </div>
              </div>
              <hr>
              <p v-if="total>0">Anda akan dialihkan ke laman pembayaran tanda konfirmasi melalui <em>payment gateway</em>
                E2Pay sebesar <strong>{{totalvalue}} ({{terbilang}})</strong>
              </p>
              <div class="col-12 spacer-field-sga">
                <LoadSpinner v-if="loading"></LoadSpinner>
              </div>
            </div>
          </div>
          <div class="row" v-if="total>0">
            <div class="col-12" v-if="messagetrx">
              <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
              <AlertDanger v-else :message="messagetrx"></AlertDanger>
            </div>
            <div v-if="successtrx" class="col-12 spacer-field-sga">
              <span>{{messagetrx}}</span>
            </div>
            <div v-else class="col-12 spacer-field-sga">
              <ButtonLoading v-if="loading"></ButtonLoading>
              <a v-else @click="doConfirmPayment" class="btn btn-md btn-success text-white"><i
                  class="fa-solid fa-caret-right"></i> Lakukan pembayaran </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import ButtonLoading from "@/components/ButtonLoading";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import LoadSpinner from "@/components/LoadSpinner";
import TabelDataBiayaPelunasan from "@/components/views/psb/part/TabelDataBiayaPelunasan";
export default {
  name: "PageBiayaPelunasan",
  components: {TabelDataBiayaPelunasan, ButtonLoading,AlertSuccess,AlertDanger,LoadSpinner},
  data: () => ({
    v$: useValidate(),
    dataPendaftaran:[],
    dataPembayaran:[],
    channelPembayaran:[],
    loading: false,
    total:0,
    totalvalue:'',
    terbilang:'',
    invid: '',
    successtrx: false,
    message:'',
    messagetrx:'',
    dataFormulir: {
      data: [],
      jenisbayar:'18'
    }
  }),
}
</script>

<style scoped>

</style>
<template>
  <Bread-crumb page="Beranda"></Bread-crumb>
  <!--content-area-sga-->
  <div class="container content-area">
    <div v-if="fromlogout" class="alert alert-warning" role="alert">
      Anda sudah keluar dari aplikasi, silahkan <router-link to="/login">login</router-link> kembali jika ingin melanjutkan.
    </div>
    <div class="row">
      <div class="col-12">
        <h3 class="judul-seksi-sga">Informasi Umum Pendaftaran</h3>
        <hr>
        <LoadSpinner v-if="loading"></LoadSpinner>
        <div v-else class="form-general">
          <div class="row">
            <div class="col-12">
              <div class="notes-notification-sga">
                <p><strong>Catatan</strong></p>
                <ol>
                  <li>Untuk membeli formulir PSB 2024/2025 silakan membuat akun baru pada sistem Sekolah Gemala Ananda. Silakan tekan "<a href="https://sa.gemalaananda.sch.id/pendaftaran-akun"><strong>buat akun"</strong></a> atau gunakan akses login yang tersedia di sebelah kanan atas.</li>
                  <li>Silakan periksa surat elektronik (email) yang anda daftarkan melalui sistem dan tekan tombol tautan "aktivasi akun" untuk memverifikasi akun anda.</li>
                  <li>Silakan login ke dalam sistem Sekolah Gemala Ananda untuk memulai tahapan pendaftaran. </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="row bg-light rounded mb-3 p-3" v-for="(dt,idx) in dataKabar" :key="idx">
            <div class="col-12 text-center">
              <p v-html="dt.text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
export default {
  name: "ParentIndex",
  components:{LoadSpinner, BreadCrumb},
  props:{
    fromlogout: Boolean
  },
  data: () => ({
    dataKabar:[],
    loading: false,
    message:''
  }),
  methods:{
    getArticle(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/gemala/info/getArticle';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataKabar = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
  },
  mounted() {
    localStorage.removeItem('tipepsb');
    this.getArticle();
  }
}
</script>

<style scoped>

</style>
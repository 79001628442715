import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import router from './router'
import store from './store/index'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App);
    app.use(router)
        .use(store)
        .use(PrimeVue)
        .use(ToastService)
        .use(ConfirmationService)
        .mount('#app')

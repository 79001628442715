<template>
  <h3 class="judul-seksi-sga">Tahun Ajaran</h3>
  <hr>
  <form>
    <div class="form-general">
      <div class="row">
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.tahunajaranpsb.$error?'text-danger':''">Nama Tahun Ajaran PSB</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.tahunajaranpsb" placeholder="misalkan: 2024/2025"
                                                      class="w-100 form-control" maxlength="9"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.tahunajaransaatini.$error?'text-danger':''">Nama Tahun Ajaran Saat ini</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.tahunajaransaatini" placeholder="misalkan: 2023/2024"
                                                      class="w-100 form-control" maxlength="9"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.kapasitas.kelas1.$error?'text-danger':''">Kapasitas kelas 1</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.kapasitas.kelas1" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.kapasitas.kelas1pindahan.$error?'text-danger':''">Kapasitas kelas 1 pindahan</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.kapasitas.kelas1pindahan" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.kapasitas.kelas2.$error?'text-danger':''">Kapasitas kelas 2</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.kapasitas.kelas2" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.kapasitas.kelas3.$error?'text-danger':''">Kapasitas kelas 3</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.kapasitas.kelas3" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.kapasitas.kelas4.$error?'text-danger':''">Kapasitas kelas 4</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.kapasitas.kelas4" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.kapasitas.kelas5.$error?'text-danger':''">Kapasitas kelas 5</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.kapasitas.kelas5" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.kapasitas.kelas6.$error?'text-danger':''">Kapasitas kelas 6</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.kapasitas.kelas6" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.maxberkas.$error?'text-danger':''">Maksimal berkas masuk</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="text" v-model="dataParameter.maxberkas" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,3)" maxlength="3"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.biaya.formulir.$error?'text-danger':''">Biaya formulir pendaftaran (Rp)</label></div>
        <div class="col-md-6 spacer-field-sga"><input type="number" v-model="dataParameter.biaya.formulir" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,9)" maxlength="9"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.biaya.konfirmasi.$error?'text-danger':''">Tanda konfirmasi pendaftaran (Rp)</label>
        </div>
        <div class="col-md-6 spacer-field-sga"><input type="number" v-model="dataParameter.biaya.konfirmasi" placeholder="masukkan angka"
                                                      class="w-100 form-control" @keypress="onInput($event,9)" maxlength="9"></div>
        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.waktubuka.$error?'text-danger':''">Waktu pembukaan pendaftaran</label></div>
        <div class="col-md-3 spacer-field-sga"><input type="date" v-model="dataParameter.waktubuka.tanggal" placeholder="dd/mm/yy"
                                                      class="w-100 form-control"></div>
        <div class="col-md-3 spacer-field-sga"><input type="time" v-model="dataParameter.waktubuka.waktu" placeholder="h:i"
                                                      class="w-100 form-control"></div>

        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.waktututup.$error?'text-danger':''">Waktu penutupan pendaftaran</label></div>
        <div class="col-md-3 spacer-field-sga"><input type="date" v-model="dataParameter.waktututup.tanggal" placeholder="dd/mm/yy"
                                                      class="w-100 form-control"></div>
        <div class="col-md-3 spacer-field-sga"><input type="time" v-model="dataParameter.waktututup.waktu" placeholder="h:i"
                                                      class="w-100 form-control"></div>

        <div class="col-md-6 spacer-field-sga"><label :class="v$.dataParameter.tahunajaranaktif.$error?'text-danger':''">Adalah tahun ajaran aktif</label><br>
          <keterangan-form>Hanya satu tahun ajaran yang dapat memiliki status aktif, mengubah
            status tahun ajaran akan mengubah status tahun ajaran yang lain
          </keterangan-form>
        </div>
        <div class="col-md-3 spacer-field-sga">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataParameter.tahunajaranaktif" name="inlineRadioOptions"
                   id="inlineRadio1" value="Ya">
            <normal-label2 class="form-check-label" for="inlineRadio1">Ya</normal-label2>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="dataParameter.tahunajaranaktif" name="inlineRadioOptions"
                   id="inlineRadio2" value="Tidak">
            <normal-label2 class="form-check-label" for="inlineRadio2">Tidak</normal-label2>
          </div>
        </div>
        <div class="col-12" v-if="messagetrx">
          <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
          <AlertDanger v-else :message="messagetrx"></AlertDanger>
        </div>
        <ConfirmDialog></ConfirmDialog>
        <div class="col-12 button-area-sga text-right"><button type="button" @click="konfirmasi" class="btn btn-md btn-success"><i
            class="fa-solid fa-caret-right"></i> Simpan</button></div>
      </div>
    </div>
  </form>
</template>

<script>
import useValidate from '@vuelidate/core'
import {required, numeric} from '@vuelidate/validators'
import DashboardServices from "@/services/dashboard.services";
import ConfirmDialog from 'primevue/confirmdialog';
import AlertDanger from "@/components/AlertDanger";
import AlertSuccess from "@/components/AlertSuccess";
import tools from "@/js/js.function.tools";
export default {
  name: "PageParameterTahunAjaran",
  props:{dataPeriode:undefined},
  components:{
    AlertSuccess,
    AlertDanger,
    ConfirmDialog
  },
  data:()=>({
    v$: useValidate(),
    success: false,
    successtrx: false,
    loading: false,
    message:'',
    messagetrx:'',
    error:'',
    dataParameter:{
      tahunajaranpsb:'',
      tahunajaransaatini:'',
      kapasitas:{
        kelas1:'',
        kelas1pindahan:'',
        kelas2:'',
        kelas3:'',
        kelas4:'',
        kelas5:'',
        kelas6:'',
      },
      maxberkas:'',
      biaya:{
        formulir:'',
        konfirmasi:'',
      },
      waktubuka:{
        tanggal:'',
        waktu:''
      },
      waktututup:{
        tanggal:'',
        waktu:''
      },
      tahunajaranaktif:false
    }
  }),
  validations() {
    return {
      dataParameter:{
        tahunajaranpsb:{required},
        tahunajaransaatini:{required},
        maxberkas: {required},
        kapasitas:{
          kelas1:{required,numeric},
          kelas1pindahan:{required,numeric},
          kelas2:{required,numeric},
          kelas3:{required,numeric},
          kelas4:{required,numeric},
          kelas5:{required,numeric},
          kelas6:{required,numeric},
        },
        biaya:{
          formulir:{required,numeric},
          konfirmasi:{required,numeric},
        },
        waktubuka:{
          tanggal: {required},
          waktu: {required}
        },
        waktututup:{
          tanggal: {required},
          waktu: {required}
        },
        tahunajaranaktif:{required}
      }
    }
  },
  methods:{
    onInput(evt, max) {
      tools.inputNumericOnly(evt, max);
    },
    async konfirmasi(){
      const result = await this.v$.dataParameter.$validate();
      if(result){
        this.$confirm.require({
          message: 'Apakah data yang Anda masukkan sudah benar?',
          header: 'Pengisian Formulir',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Ya',
          rejectLabel: 'Cek kembali',
          defaultFocus:'reject',
          accept: () => {
            this.doSubmitIsiFormulir();
          },
          reject: () => {
          }
        });
      }else{
        this.successtrx = false;
        this.messagetrx = 'Silahkan isi formulir dengan benar';
      }
    },
    async doSubmitIsiFormulir(){
      const result = await this.v$.dataParameter.$validate();
      if(result){
        this.error = false;
        this.loading = true;
        this.$store.commit('loading',this.loading);
        let data = this.dataParameter;
        let path = '/dashboard/saveParameterTahunAjaran';
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.messagetrx = response.data.message;
              if(response.data.status===200){
                this.successtrx = true;
                this.error = false;
              }else {
                this.error = true;
                this.successtrx = false;
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.error = true;
        this.loading = false;
        this.successtrx = false;
        this.messagetrx = 'Mohon lengkapi data terlebih dahulu';
        this.$store.commit('loading',this.loading);
      }
    },
  },
  mounted() {
    if(this.dataPeriode != undefined){
      if(Object.keys( this.dataPeriode.data ).length !== 0){
        this.dataParameter = this.dataPeriode.data.view;
      }
    }
  },

}
</script>

<style scoped>

</style>
<template>
  <Bread-crumb page="Beranda > Pendaftaran > Pembelian formulir > Pengisian"></Bread-crumb>
  <div class="container content-area">
    <div class="row" v-if="!isHaveNoFormulir">
      <div class="col-12">
        <h3 class="judul-seksi-sga">{{this.$store.getters.getJudul}}</h3>
        <hr>
        <div v-if="!this.$store.state.formulirAgreement" class="alert alert-danger" role="alert">
          Anda belum menyetujui tata cara, silakan kembali ke halaman persetujuan.
        </div>
        <form v-else class="needs-validation" novalidate>
          <div class="form-general">
            <div class="row">
              <div class="col-12">
                <div class="notes-notification-sga">
                  <p><strong>Catatan</strong>
                  <ul>
                    <li>
                      Isilah form berikut secara lengkap dan benar. Anda akan menerima konfirmasi
                      dan instruksi pembayaran pada layar berikutnya.
                    </li>
                    <li>Lakukan pembayaran.</li>
                    <li>Setelah <strong>pembayaran diterima</strong>, Anda akan menerima instruksi
                      melalui surat elektronik. <strong>Anda dapat mengakses dan mulai mengisi
                        serta melengkapi formulir pendaftaran.</strong></li>
                  </ul>
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12"><h4>Data Ananda</h4></div>
            </div>
            <div class="group-sga">
              <div class="row border-success border-3 mb-3 pt-2 pb-2 rounded" v-for="(dataanak,index) in dataFormulir.anak" :key="index" :class="getDivider(index)">
                <LoadSpinner v-if="loading"></LoadSpinner>
                <FormDataAnak v-else :index="index" :anak="dataanak"
                              @change-nama="updateNama($event,index)"
                              @change-nik="updateNik($event,index)"
                              @change-ttl="updateTtl($event,index)"
                              @change-kelas="updateKelas($event,index)"
                              @change-ajaran="updateAjaran($event,index)"
                              @change-asal="updateSekolahAsal($event,index)"
                              @change-jenisdaftar="updateJenisPendaftaran($event,index)"
                              :jenis-ajaran="jenisAjaran"
                              :jenis-kelas="jenisKelas"
                              :jenis-pendaftaran="jenisPendaftaran"
                              :nikduplikat="nikduplikat"
                >
                </FormDataAnak>
              </div>
              <div class="row">
                <div class="col-12 button-area-sga text-right">
                  <a @click="remFormDataAnak" v-show="dataFormulir.anak.length>1" class="btn btn-md btn-danger mr-2 text-white">- <i class="fa-solid fa-child"></i> Anak</a>
                  <a @click="addFormDataAnak" v-show="dataFormulir.anak.length<getMaxAnak()" class="btn btn-md btn-warning">+ <i class="fa-solid fa-child"></i> Anak</a>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4 spacer-field-sga"><label>Apakah ananda memiliki
                  <em>sibling</em>?</label></div>
                <div class="col-md-8 spacer-field-sga">
                  <select class="w-100 form-control" v-model="dataFormulir.sibling" required>
                    <option value="1">Ya</option>
                    <option value="2">Tidak</option>
                  </select>
                </div>
                <div v-if="issibling" class="col-md-4 spacer-field-sga"><label>Nama <em>sibling</em></label></div>
                <div v-if="issibling" class="col-md-8 spacer-field-sga"><input type="text" v-model="dataFormulir.siblingnama"
                                                                               class="w-100 form-control"
                                                                               required>
                  <span class="keterangan-form" :class="v$.dataFormulir.siblingnama.$error?'text-danger':''">Tuliskan nama lengkap <em>sibling</em></span>
                </div>
                <div v-if="issibling" class="col-md-4 spacer-field-sga"><label>Bersekolah di kelas</label></div>
                <div v-if="issibling" class="col-md-8 spacer-field-sga"><input type="text" v-model="dataFormulir.siblingkelas"
                                                                               class="w-100 form-control"
                                                                               required>
                  <span class="keterangan-form" :class="v$.dataFormulir.siblingkelas.$error?'text-danger':''">Tuliskan kelas <em>sibling</em>. Bila sudah alumni, tulis "alumni" dan tahun lulus.</span>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12"><h4>Data Orang Tua</h4></div>
            </div>
            <div class="row">
              <div class="col-md-4 spacer-field-sga"><label>Nama orang tua</label></div>
              <div class="col-md-8 spacer-field-sga"><input type="text" v-model="dataFormulir.orangtua.nama" class="w-100 form-control" required>
                <span class="keterangan-form" :class="v$.dataFormulir.orangtua.nama.$error?'text-danger':''">Tuliskan nama lengkap orang tua</span>
              </div>
              <div class="col-md-4 spacer-field-sga"><label>Status hubungan</label></div>
              <div class="col-md-8 spacer-field-sga"><select name="Kelas" id="statushubungan" v-model="dataFormulir.orangtua.statushubungan" class="w-100 form-control" required="" wtx-context="30258D9D-1754-4520-8A95-2433D186E805">
                <option v-for="(status,idx) in jenisHubungan" :key="idx" :value="status.code">{{status.value}}</option>
              </select>
                <span class="keterangan-form" :class="v$.dataFormulir.orangtua.statushubungan.$error?'text-danger':''">Status hubungan</span>
              </div>
              <div class="col-md-4 spacer-field-sga"><label>No telepon genggam</label></div>
              <div class="col-md-8 spacer-field-sga"><input type="text" v-model="dataFormulir.orangtua.hp" @keypress="onInput($event,15)" class="w-100 form-control" required>
                <span class="keterangan-form" :class="v$.dataFormulir.orangtua.hp.$error?'text-danger':''">Diisi dengan angka, minimal 8 maksimal 15 digit.({{getInfoNoTelpon}})</span>
              </div>
              <div class="col-md-4 spacer-field-sga"><label>Alamat surel</label></div>
              <div class="col-md-8 spacer-field-sga"><input disabled="disabled" type="text" v-model="dataFormulir.orangtua.email" @keypress="onInput($event,15)" class="w-100 form-control" required>
                <span class="keterangan-form" :class="v$.dataFormulir.orangtua.email.$error?'text-danger':''">Silakan isi alamat surel</span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12"><h4>Pembayaran formulir</h4></div>
            </div>
            <div class="row">
              <div class="col-12"><p>Jumlah yang harus dibayarkan adalah sebesar <strong>{{biaya}} ({{terbilang}})</strong> untuk <strong>{{totalFormulir}}</strong> formulir
                pendaftaran.</p>
                <p>
                  <LoadSpinner v-if="loading"></LoadSpinner>
                </p>
              </div>
              <div class="col-12" v-if="messagetrx">
                <AlertSuccess v-if="successtrx" :message="messagetrx"></AlertSuccess>
                <AlertDanger v-else :message="messagetrx"></AlertDanger>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 button-area-sga text-right" v-if="this.$store.state.formulirAgreement">
                <ConfirmDialog></ConfirmDialog>
                <button class="btn btn-md btn-success" type="button" :disabled="isDisabled" @click="konfirmasi"><i class="fa-solid fa-caret-right"></i> Daftar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="alert alert-info" role="alert">
          Silakan melakukan pembayaran formulir, untuk mendapatkan akses pengisian formulir.
        </div>
        <LoadSpinner v-if="loading"></LoadSpinner>
        <div v-else class="form-general">
          <div class="row" v-if="dataPendaftaran.length>0">
            <div class="col-12 text-left">
              <div class="table-data-sga">
                <div class="table-responsive">
                  <TableDataFormulir :dataformulir="dataPendaftaran" routepath="/gemala/formulir/bayar-biaya-formulir/" routeterbayar="/gemala/psb/isi-formulir-psb" buttontitle="Bayar biaya formulir" buttontitle2="Isi Formulir"></TableDataFormulir>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import FormDataAnak from "@/components/views/formulir/part/FormDataAnak";
import useValidate from '@vuelidate/core';
import tools from '@/js/js.function.tools';
import {helpers,required,numeric,email, minLength,maxLength,requiredIf} from '@vuelidate/validators'
import router from "@/router";
import DashboardServices from "@/services/dashboard.services";
import LoadSpinner from "@/components/LoadSpinner";
import AlertSuccess from "@/components/AlertSuccess";
import AlertDanger from "@/components/AlertDanger";
import TableDataFormulir from "@/components/views/formulir/part/TableDataFormulir";
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  name: "PageFormulirPembelian",
  components: {TableDataFormulir,ConfirmDialog, AlertDanger, AlertSuccess, LoadSpinner, BreadCrumb,FormDataAnak},
  data:()=>({
    v$: useValidate(),
    maxanak: 5,
    loading: false,
    loadingtrx: false,
    message:'',
    success: false,
    successtrx: false,
    messagetrx:'',
    jenisHubungan:[],
    jumlahanak:0,
    isHaveNoFormulir: true,
    allNik:[],
    nikduplikat:false,
    channelPembayaran:[],
    dataPendaftaran:[],
    jenisPendaftaran:[],
    jenisAjaran:[],
    jenisKelas:[],
    calculateData:'',
    biaya:0,
    terbilang:'',
    totalFormulir:0,
    transaksi:'',
    issibling: false,
    dataFormulir:{
      jenisbayar:'18',
      totaltagihan:0,
      orangtua:{
        nama:'',
        statushubungan:'',
        hp:'',
        email:''
      },
      sibling:'',
      siblingnama:'',
      siblingkelas: '',
      anak:[]
    },
  }),
  computed:{
    isDisabled(){
      //return !this.successtrx ? 'disabled' : '';
      return false;
    },
    getInfoNoTelpon(){
      return this.dataFormulir.orangtua.hp.length+'/'+15;
    },
  },
  methods:{
    async konfirmasi(){
      const result = await this.v$.dataFormulir.$validate();
      if(result){
        this.$confirm.require({
          message: 'Apakah data yang Anda masukkan sudah benar?',
          header: 'Pembelian Formulir',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Ya',
          rejectLabel: 'Cek kembali',
          defaultFocus:'reject',
          accept: () => {
            this.submitPendaftaran();
          },
          reject: () => {
          }
        });
      }else{
        this.successtrx = false;
        this.messagetrx = 'Silahkan isi formulir dengan benar';
      }
    },
    isNikDuplicate(){
      let nik = this.getNikAnakAll();
      return this.hasDuplicates(nik);
    },
    getNikAnakAll(){
      let tmpnik = [];
      this.dataFormulir.anak.forEach((element) => {
        tmpnik.push(element.nikanak);
      });
      this.allNik = tmpnik;
      return tmpnik;
    },
    hasDuplicates(array) {
      var valuesSoFar = Object.create(null);
      for (var i = 0; i < array.length; ++i) {
        var value = array[i];
        if (value in valuesSoFar) {
          return true;
        }
        valuesSoFar[value] = true;
      }
      return false;
    },
    doClearForm(){
      this.dataFormulir.jenisbayar='18';
      this.dataFormulir.totaltagihan=0;
      this.dataFormulir.orangtua.nama='';
      this.dataFormulir.statushubungan='';
      this.dataFormulir.hp='';
      this.dataFormulir.email='';
      this.dataFormulir.sibling='';
      this.dataFormulir.siblingnama='';
      this.dataFormulir.siblingkelas='';
      this.dataFormulir.anak=[];
      this.addFormDataAnak();
    },
    getDataFormulirPendaftaran(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {};
      let path = '/dashboard/getDataFormulirPendaftaran';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataPendaftaran = this.response.data;
              this.isHaveNoFormulir = this.dataPendaftaran.length > 0;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getJenisPendaftaran(){
      this.loading = true;
      let data = {"paramname":"jenis-pendaftaran"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.jenisPendaftaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getTahunAjaran(){
      this.loading = true;
      let data = {"paramname":"tahun-ajaran"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.jenisAjaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getJenisKelas(){
      this.loading = true;
      let data = {"paramname":"kelas","id":"pindahan"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.jenisKelas = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    calculateTrx(){
      this.loadingtrx = true;
      let data = this.dataFormulir;
      let path = '/dashboard/calculateFormulir';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.calculateData = this.response.data;
              this.biaya = this.calculateData.biayavalue;
              this.terbilang = this.calculateData.terbilang;
              this.totalFormulir = this.calculateData.totalFormulir;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getStatusHubungan(){
      this.loading = true;
      let data = {"paramname":"statushubungan"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.jenisHubungan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getChannelPembayaran(){
      this.loading = true;
      let data = {"paramname":"metode-bayar"};
      let path = '/dashboard/getParameter';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.channelPembayaran = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    getMaxAnak(){
      return this.maxanak+1;
    },
    getDivider(idx){
      if(this.dataFormulir.anak.length>1){
        if(idx<this.dataFormulir.anak.length-1){
          return 'border-bottom pb-4';
        }
      }
    },
    getFormulirAggreement(){
      let agree = localStorage.getItem('user-agreement');
      this.$store.commit('setFormulirAgreement', agree);
      return agree;
    },
    getInfoLength(data,max){
      data = 'saya suka';
      return data.length+'/'+max;
    },
    onInput(evt,max) {
      tools.inputNumericOnly(evt,max)
    },
    validate(){
      this.v$.dataFormulir.$validate();
    },
    async submitPendaftaran(){
      const result = await this.v$.dataFormulir.$validate();
      if(result){
        this.loading = true;
        this.successtrx = false;
        this.$store.commit('loading',this.loading);
        let data = this.dataFormulir;
        let path = '/dashboard/submitBeliFormulir';
        DashboardServices.postData(data,path).then(
            (response)=>{
              this.response = response.data;
              this.messagetrx = response.data.message;
              if(response.data.status===200){
                this.successtrx = true;
                this.transaksi = this.response.data;
                this.doClearForm();
                this.getDataFormulirPendaftaran();
              }else {
                this.successtrx = false;
              }
              this.loading = false;
              this.$store.commit('loading',this.loading);
            }
        );
      }else{
        this.successtrx = false;
        this.messagetrx = 'Silahkan isi formulir dengan benar';
      }
    },
    addFormDataAnak(){
      let dataanak={
        nama:'',
        kelas:'',
        tahunajaran: '',
        nikanak:'',
        ttl:'',
        sekolahasal:'',
        jenisdaftar:'',
        sibling:'',
        siblingnama:'',
        siblingkelas:'',
      };
      if(this.dataFormulir.anak.length<=this.maxanak){
        this.dataFormulir.anak.push(dataanak)
      }
      this.calculateTrx();
    },
    remFormDataAnak(){
      this.dataFormulir.anak.pop();
      this.calculateTrx();
    },
    updatePembayaran(value){
      this.dataFormulir.jenisbayar = value;
    },
    updateSibling(value,index){
      this.dataFormulir.anak[index].sibling = value;
    },
    updateSiblingNama(value,index){
      this.dataFormulir.anak[index].siblingnama = value;
    },
    updateSiblingKelas(value,index){
      this.dataFormulir.anak[index].siblingkelas = value;
    },
    updateJenisPendaftaran(value,index){
      this.dataFormulir.anak[index].jenisdaftar = value;
    },
    updateNama(value,index){
      this.dataFormulir.anak[index].nama = value;
    },
    updateNik(value,index){
      this.dataFormulir.anak[index].nikanak = value;
      this.nikduplikat = false;
      if(this.isNikDuplicate()){
        this.nikduplikat = true;
      }
    },
    updateKelas(value,index){
      this.dataFormulir.anak[index].kelas = value;
    },
    updateAjaran(value,index){
      this.dataFormulir.anak[index].tahunajaran = value;
    },
    updateTtl(value,index){
      this.dataFormulir.anak[index].ttl = value;
    },
    updateSekolahAsal(value,index){
      this.dataFormulir.anak[index].sekolahasal = value;
    },
  },
  validations(){
    return {
      dataFormulir:{
        orangtua:{
          nama: {required, minLength: minLength(2), maxLength: maxLength(100)},
          statushubungan: {required},
          email: {required,email},
          hp: {required, minLength: minLength(9), maxLength:maxLength(15)},
        },
        sibling: {required},
        siblingnama: {required: requiredIf(this.issibling)},
        siblingkelas: {required: requiredIf(this.issibling)},
        jenisbayar: {required,numeric},
        anak:{
          $each:helpers.forEach({
            jenisdaftar:{required},
            nama: {required, minLength: minLength(2), maxLength: maxLength(100)},
            kelas: {required: requiredIf(this.$store.state.pindahan), minLength: minLength(1), maxLength: maxLength(10)},
            tahunajaran: {required: requiredIf(this.$store.state.pindahan), minLength: minLength(2), maxLength:maxLength(10)},
            nikanak: {required, minLength: minLength(16), maxLength: maxLength(16)},
            ttl: {required, minLength: minLength(2), maxLength: maxLength(20)},
            sekolahasal: {required, minLength: minLength(2),maxLength: maxLength(100)},
          })
        }
      }
    }
  },
  beforeMount() {
    this.addFormDataAnak();
  },
  mounted() {
    let tipepsb = localStorage.getItem('tipepsb');
    this.$store.commit('tipepsb',tipepsb);
    if(this.$store.state.titlepsb==='-'){
      router.push('/pendaftaran');
    }
    this.validate();
    this.getDataFormulirPendaftaran();
    this.getFormulirAggreement();
    this.dataFormulir.orangtua.email = this.$store.getters.getUserEmail;
    this.getJenisPendaftaran();
    this.getJenisKelas();
    this.getTahunAjaran();
    this.getStatusHubungan();
    this.getChannelPembayaran();
  },
  watch: {
    dataFormulir: {
      immediate: true,
      deep: true,
      handler() {
        if(this.dataFormulir.sibling==='1'){
          this.issibling = true;
        }else{
          this.issibling = false;
        }
      }
    },
  }
}
</script>
<style scoped>

</style>
<template>
  <!--Pernyataan-Orang-tua-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>H. Pernyataan Orang Tua
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        {{dataFormulir.dataFormulir}}
        <div class="col-12">
          <label :class="v$.dataFormulir.pernyataan.$error?'text-danger':''">Berikan tanda centang pada setiap butir di bawah ini untuk menyatakan Anda memahami dan menyetujuinya</label>
        </div>
        <div class="col-12">
          <div v-for="(item,idx) in dataTxtPernyataan" :key="idx" class="form-check form-check-inline spacing-checkbox-sga">
            <input class="form-check-input" type="checkbox" v-model="dataFormulir.pernyataan" id="inlineCheckbox1" :value="item.code"  required>
            <span class="normal-label2 form-check-label" for="inlineCheckbox1">
              {{item.text}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Pengembalian-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Pengembalian
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12"><p>Apabila anak kami tidak dapat bergabung di SD Gemala Ananda, maka Tanda Konfirmasi dapat dikembalikan ke rekening berikut:</p>	</div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <label>Nama pemilik rekening</label>
          <input type="text" v-model="dataFormulir.bank.namarekening" class="w-100 form-control" required>
          <span class="keterangan-form" :class="v$.dataFormulir.bank.namarekening.$error?'text-danger':''">Tuliskan nama rekening</span>
        </div>
        <div class="col-12 spacer-field-sga">
          <label>No rekening</label>
          <input type="text" v-model="dataFormulir.bank.norekening" class="w-100 form-control"  required>
          <span class="keterangan-form" :class="v$.dataFormulir.bank.norekening.$error?'text-danger':''">Tuliskan no rekening</span>
        </div></div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-12 spacer-field-sga">
          <label>Nama Bank</label>
          <input type="text" v-model="dataFormulir.bank.banknama" class="w-100 form-control" required >
          <span class="keterangan-form" :class="v$.dataFormulir.bank.banknama.$error?'text-danger':''">Tuliskan nama Bank</span>
        </div>
      </div>
    </div>
  </div>
  <!--Lampiran-foto-->
  <div class="row title-section-form-sga">
    <div class="col-12">
      <hr><h3>Lampiran Foto
    </h3><hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12"><label :class="v$.dataFormulir.photo.$error?'text-danger':''">Unggah foto berikut</label><p><ul><li>Ananda dalam pose seluruh tubuh</li><li>Seluruh keluarga bersama (foto menonjolkan keluarga, bukan latar belakang/pemandangan)</li></ul></p>	</div>
    <div class="col-12 spacer-field-sga">
      <div class="custom-file">
        <ProgressBar v-if="isupload" class="mt-2 mb-2" :value="uploadprogress" style="height: 10px;font-size: 14px"></ProgressBar>
        <FileLister v-if="files.lampiranfoto.size!=0" :filedata="files.lampiranfoto.list" :num-visible="files.lampiranfoto.size"></FileLister>
        <FileUpload v-if="showupload" :class="classupload" :showUploadButton="false" :showCancelButton="false" :multiple="false" :fileLimit="3" :chooseLabel="uploadLabel" @before-send="beforeUpload"  name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg," :maxFileSize="5000000" @progress="onProgress" @upload="onUpload" @select="onSelect" >
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
        <span class="keterangan-form">Pilih berkas maksimum berukuran 1024kb. Hanya .jpg | Maksimum 3 berkas | Minimum 2 berkas</span>
      </div>
    </div>
  </div>
</template>

<script>
import "primevue/resources/themes/lara-light-indigo/theme.css";
import useValidate from "@vuelidate/core";
import {required,minLength} from "@vuelidate/validators";
import FileUpload from 'primevue/fileupload';
import DashboardServices from "@/services/dashboard.services";
import ProgressBar from 'primevue/progressbar';
import FileLister from "@/components/FileLister";

export default {
  name: "PageFormPernyataanOrangTua",
  props:{
    data:[],
    idanak:String,
    initdata: undefined,
    showupload: Boolean
  },
  components: {FileLister, FileUpload,ProgressBar},
  emits: ['change-data'],
  data:()=>({
    v$: useValidate(),
    loading: false,
    classupload:'',
    message:'',
    success:false,
    files:{
      lampiranfoto:{
        size:0,
        list:[]
      }
    },
    urlupload:'',
    dataTxtPernyataan:[],
    uploadprogress:0,
    uploadLabel: 'Pilih',
    isupload: false,
    dataFormulir:{
      pernyataan:[],
      bank:{
        namarekening:'',
        norekening:'',
        banknama:'',
        bankcabang:'_',
      },
      photo:''
    }
  }),
  methods:{
    getDataUploadedFile(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:this.idanak};
      let path = '/dashboard/getListFile';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              if (typeof this.response.data.lampiranfoto != "undefined") {
                this.files.lampiranfoto.list = this.response.data.lampiranfoto.list;
                this.files.lampiranfoto.size = this.response.data.lampiranfoto.size;
                if(this.files.lampiranfoto.size>0){
                  this.dataFormulir.photo = 'uploaded';
                }
              }
              this.success = true;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    },
    beforeUpload(request){
      let user = JSON.parse(localStorage.getItem('usergemala'));
      request.xhr.setRequestHeader('Authorization', 'Bearer ' + user.token);
      request.formData.append('paramname','lampiranfoto');
      request.formData.append('invid',this.idanak);
      return request;
    },
    onProgress(event){
      this.isupload = true;
      this.uploadprogress = event.progress;
    },
    onUpload(event){
      let response = event.xhr.response;
      const obj = JSON.parse(response);
      let message = obj.message;
      if(obj.code===1){
        this.dataFormulir.photo = obj.data.fileid;
        this.classupload='bg-success';
        this.$toast.add({ severity: 'success', summary: message, detail: 'File Uploaded', life: 3000 });
      }else{
        this.classupload='bg-danger';
        this.$toast.add({ severity: 'danger', summary: message, detail: 'File Uploaded', life: 3000 });
      }
      this.isupload = false;
    },
    onSelect(event){
      if(event.files.length >0){
        //this.uploadLabel = event.files[0].name;
      }
      this.photo = '';
    },
    validate() {
      this.v$.dataFormulir.$validate();
    },
    getListPernyataan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {paramname:'psb-pernyataan-orang-tua'};
      let path = '/dashboard/getListPernyataan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataTxtPernyataan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getListPernyataan();
    //this.$emit('change-data',this.dataFormulir);
    let path = '/dashboard/uploadFile';
    this.urlupload = DashboardServices.generateUrl(path);
    this.dataFormulir = this.initdata;
    this.$emit('change-data',this.initdata);
    this.validate();
    this.getDataUploadedFile();
  },
  validations() {
    return {
      dataFormulir:{
        bersedia: {required },
        menyadari: {required},
        menjamin: {required },
        pernyataan:{required,minLength: minLength(3)},
        bank:{
          namarekening: {required },
          norekening: {required },
          banknama: {required },
          bankcabang: {},
        },
        photo:{ required}
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <BreadCrumb page="Beranda > Pendaftaran > Data Keputusan > Lihat Data Keputusan > Ubah data"></BreadCrumb>
  <div class="container content-area">
    <div class="row">
      <div class="col-md-10">
        <h3 class="judul-seksi-sga">Data Hasil Wawancara - {{data.nama_anak}} </h3>
      </div>
      <div class="col-md-2 text-right my-auto">
        <a href="#" @click="toggleview" class="btn btn-sm btn-light"><i class="fa-solid fa-caret-left"></i></a>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="form-general">
        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="col-12 spacer-field-sga">
                <normal-label>No formulir</normal-label>
                <br>
                <normal-label2>{{data.no_formulir}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Ananda</normal-label>
                <br>
                <normal-label2>{{data.nama_anak}}</normal-label2>
              </div>
              <div class="col-12 spacer-field-sga">
                <normal-label>Nama Orang Tua</normal-label>
                <ul>
                  <li>
                    <normal-label2>{{data.nama_ayah}}</normal-label2>
                  </li>
                  <li>
                    <normal-label2>{{data.nama_ibu}}</normal-label2>
                  </li>
                  <li v-if="data.nama_ayah_tiri">{{data.nama_ayah_tiri}}</li>
                  <li v-if="data.nama_ibu_tiri">{{data.nama_ibu_tiri}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a :class="getClass(1)" @click="setpage(1)" data-toggle="tab" href="#home">Catatan Wawancara</a>
              </li>
              <li class="nav-item">
                <a :class="getClass(2)" @click="setpage(2)" data-toggle="tab" href="#menu1">Data yang tersedia</a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content" style="margin-top: 30px;">
              <div :class="getTabClass(1)" id="home">
                <div class="row">
                  <div class="col-12 spacer-field-sga  w-100"><label>Catatan hasil wawancara</label>
                  </div>
                  <div class="col-12  spacer-field-sg">
                                        <textarea class="form-control textarea-sga" v-model="catatan"
                                                  rows="4" required=""></textarea>
                  </div>
                  <div class="col-12 button-area-sga text-right">
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-12">
                    <label>Dokumen wawancara : </label>
                    <div class="custom-file">
                      <FileLister v-if="!loading" :filedata="filelist.list" :num-visible="filelist.size"></FileLister>
                      <FileUpload v-if="showupload" :class="classupload1" :fileLimit="10" :showUploadButton="false" :showCancelButton="false" :multiple="true" :chooseLabel="uploadLabel1" @before-send="beforeUploadSatu" name="fileupload" :url=urlupload :auto="true" accept="image/jpg,image/jpeg,application/pdf" :maxFileSize="5500000" @progress="onProgressSatu" @upload="onUploadSatu" @select="onSelectSatu"></FileUpload>
                      <keterangan-form>Pilih berkas maksimum berukuran 1024kb. Hanya .jpg atau
                        .pdf
                      </keterangan-form>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <normal-label>Status Rekomendasi : <select name="status-pelaksanaan-penilaian"
                                                               class="sga-status-dropdown" v-model="statuspelaksanaan">

                      <option value="Belum ada rekomendasi">belum ada rekomendasi</option>
                      <option value="Test Kognitif">Test Kognitif</option>
                      <option value="Rekomendasi tidak diterima">Rekomendasi tidak diterima</option>
                      <option value="Rekomendasi diterima">Rekomendasi diterima</option>
                    </select></normal-label>
                    <button @click="updateHasilWawancaraOrtu" class="btn btn-sm btn-success">Simpan</button>
                  </div>
                </div>
              </div>
              <div :class="getTabClass(2)" id="menu1">
                <div class="row">
                  <div class="col-12"><h5 style="color: darkorange;">Formulir pendaftaran</h5>
                    <p>
                      <router-link :to=generatelink(data.invid) target= '_blank'>
                        Lihat formulir
                      </router-link>
                    </p>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data wawancara awal
                    (Psikologi)</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{datacatatan.psikolog}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPsikolog.list" :num-visible="filelistPsikolog.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data test kognitif </h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan </strong> :</p>
                        <p>{{datacatatan.kognitif}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistKognitif.list" :num-visible="filelistKognitif.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data kelas coba belajar</h5>
                    <div class="row">
                      <div class="col-12"><p><strong>Catatan PIC PSB</strong> :</p>
                        <p>{{datacatatan.cobabelajar}}</p>
                      </div>
                      <div class="col-12"><p><strong>Dokumen </strong> :</p>
                        <FileLister v-if="!loading" :filedata="filelistPercobaan.list" :num-visible="filelistPercobaan.size"></FileLister>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data formulir Berasa</h5>
                    <div class="row">
                      <div class="col-12">
                        <ul>
                          <li v-for="(berasa,idx) in berasa" :key="idx">
                            <span class="text-primary" style="cursor: pointer" @click="viewberasa(berasa)" target="_blank">{{berasa.tipe_pengisi}}</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12"><h5 style="color: darkorange;">Data Catatan Diskusi</h5>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Akademik</strong>:</p>
                        <p>{{datacatatan.catatandiskusi.scoring_akademik}}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Akademik Anak</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_akademik}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <p><strong>Scoring Sikap</strong>:</p>
                        <p>{{datacatatan.catatandiskusi.scoring_sikap}}</p>
                      </div>
                      <div class="col-md-9">
                        <p><strong>Catatan Sikap Anak</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_sikap}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12"><p><strong>Yang masih perlu dikembangkan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.masih_perlu_kembangkan}}</p>
                      </div>
                      <div class="col-12"><p><strong>Apa yang perlu ditanyakan lebih lanjut kepada
                        orang tua</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.tanya_ortu}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kualitas informasi</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kualitas_informasi}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kualitas_informasi_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Temperamen</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.temperamen}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.temperamen_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Keterlibatan orang tua</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.keterlibatan_ortu}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.keterlibatan_ortu_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6"><p><strong>Kedekatan dalam keluarga</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kedekatan_keluarga}}</p>
                      </div>
                      <div class="col-6"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kedekatan_keluarga_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Aktivitas</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.aktivitas}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kemandirian</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kemandirian}}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kemandirian_catatan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Limitasi kesehatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.limitasi}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kebutuhan khusus/kesubel</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kebutuhan_khsusu}}</p>
                      </div>
                      <div class="col-4"><p><strong>Tambahan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.tambahan}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"><p><strong>Raport TK</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.raport_tk}}</p>
                      </div>
                      <div class="col-4"><p><strong>Kehadiran</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.kehadiran}}</p>
                        <p>{{datacatatan.catatandiskusi.kehadiran_catatan}}</p>
                      </div>
                      <div class="col-4"><p><strong>Catatan</strong> :</p>
                        <p>{{datacatatan.catatandiskusi.catatan_akhir}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "PageUpdateDataKeputusan",
  components:{BreadCrumb}
}
</script>

<style scoped>

</style>